/**
 * Created by John on 6/2/2016.
 */
//console.log = function(){};

/*
function delay(duration) {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), duration);
  });
}
*/

/*
if (screen && screen.orientation && screen.orientation.lock) {
  screen.orientation.lock("portrait");
}
*/


/* No longer used, but keeping in case its useful later

window.addEventListener('message', event => {
  // IMPORTANT: check the origin of the data! 
  if (event.origin.startsWith('http://api.portal.localhost') ||
    event.origin.startsWith('https://dev.wallet.inc') ||
    event.origin.startsWith('https://staging.wallet.inc') ||
    event.origin.startsWith('https://wallet.inc')) {
    // The data was sent from your site.
    // Data sent with postMessage is stored in event.data:
    console.log(event.data);
    var button = "";
    switch (event.data) {
      case "AC":
        button = "btnAdCredits";
        break;
      case "SV":
        button = "btnStaticVouchers";
        break;
      case "DV":
        button = "btnDynamicVouchers";
        break;
      case "TR":
        button = "btnMembershipTier";
        break;
      case "CM":
        button = "btnMembershipPoints";
        break;
      case "ML":
        button = "btnMembershipLevel";
        break;
      case "MC":
        button = "btnMerchantCredit";
        break;
      case "PC":
        button = "btnPromotions";
        break;
      case "GD":
        button = "btnGiftCards";
        break;
      case "GC":
        button = "btnGiftCertificates";
        break;
      case "LC":
        button = "btnChat";
        break;
      case "TH":
        button = "btnTransactionHistory";
        break;
      case "PO":
        button = "btnProfile";
        break;
      case "ST":
        button = "btnSettings";
        break;
      case "NA":
        button = "btnNews";
        break;
      case "PF":
        button = "btnPerformances";
        break;
      case "LB":
        button = "btnLinkBook";
        break;
      case "IG":
        button = "btnImageGrid";
        break;
      case "WC":
        button = "btnCall";
        break;
      case "RP":
        button = "btnRepresentatives";
        break;
      case "MD":
        button = "btnMapDirections";
        break;
      default:
        break;
    }

    if (button) Wallet.Animations.pageChange(button)

  } else {
    // The data was NOT sent from your site! 
    // Be careful! Do not use it. This else branch is
    // here just for clarity, you usually shouldn't need it.
    return;
  }
});
 */

window.addEventListener('popstate', function (event) {
  var state = event.state;
  if (state && state.pageData && state.pageData.ID) {
    //window.location.href = "/" + state.pageData.ID;
    console.log("STATE: " + state.pageData.ID);
    setTimeout(function () {
      Wallet.loadWallet(state.pageData.ID);
    }, 1)
  }
});


//granular control of JQuery Mobile, override abiliy for pagecontainer functions.
$(document).on("pagecreate", function () {
  $.mobile.pageContainer.pagecontainer({
    defaults: {
      history: false // Ensure a new history entry is created for all page changes
    },
    disabled: true,

    beforechange: function (event, ui) {
      //event.preventDefault();
      console.log("JQM: beforechange");
    },
    beforehide: function (event, ui) {
      event.preventDefault();
      console.log("JQM: beforehide");
    },
    beforeload: function (event, ui) {
      console.log("JQM: beforeload");
    },
    beforeshow: function (event, ui) {
      event.preventDefault();
      console.log("JQM: beforeshow");
    },
    beforetransition: function (event, ui) {
      //event.preventDefault();
      console.log("JQM: beforetransition");
    },
    change: function (event, ui) {
      console.log("JQM: change");
    },
    changefailed: function (event, ui) {
      console.log("JQM: changefailed");
    },
    load: function (event, ui) {
      console.log("JQM: load");
    },
    create: function (event, ui) {
      console.log("JQM: create");
    },
    hide: function (event, ui) {
      console.log("JQM: hide");
    },
    loadfailed: function (event, ui) {
      console.log("JQM: loadfailed");
    },
    remove: function (event, ui) {
      console.log("JQM: remove");
    },
    show: function (event, ui) {
      console.log("JQM: show");
    },
    remove: function (event, ui) {
      console.log("JQM: remove");
    },
    transition: function (event, ui) {
      console.log("JQM: transition");
    },
  });
});



// object.watch
if (!Object.prototype.watch) {
  Object.defineProperty(Object.prototype, "watch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function (prop, handler) {
      var oldval = this[prop],
        newval = oldval,
        getter = function () {
          return newval;
        },
        setter = function (val) {
          if (!val) return;
          oldval = newval;
          return newval = handler.call(this, prop, oldval, val);
        };

      if (delete this[prop]) { // can't watch constants
        Object.defineProperty(this, prop, {
          get: getter,
          set: setter,
          enumerable: true,
          configurable: true
        });
      }
    }
  });
}

// object.unwatch
if (!Object.prototype.unwatch) {
  Object.defineProperty(Object.prototype, "unwatch", {
    enumerable: false,
    configurable: true,
    writable: false,
    value: function (prop) {
      var val = this[prop];
      delete this[prop]; // remove accessors
      this[prop] = val;
    }
  });
}



//IIFE, automatically executes when page is fully loaded.
$(function () {
  $(window).on("navigate", function (event, data) {
    console.log("JQUERY NAVIGATE EVENT: " + data.state);
  });


  if (typeof localStorage === 'object') {
    try {
      localStorage.setItem('localStorage', 1);
      localStorage.removeItem('localStorage');
    } catch (e) {
      Storage.prototype._setItem = Storage.prototype.setItem;
      Storage.prototype.setItem = function () { };
      //alert('Your web browser does not support storing settings locally.
      // In Safari, the most common cause of this is using "Private Browsing Mode".
      // Some settings may not save or some features may not work properly for you.');
    }
  }





  $(document).on("pagecontainershow", function (e, data) {
    console.log("pagecontainershow");
    var activePage = data.toPage;

    setTimeout(function () {
      var currentHeight = activePage.css("min-height");
      activePage.css("min-height", currentHeight - 1); /* subtract 1px or set your custom height */
    }, 50); /* set delay here */
  });

  $(window).on("throttledresize", function (e) {
    console.log("throttledresize");
    var activePage = $.mobile.pageContainer.pagecontainer("getActivePage");

    setTimeout(function () {
      var currentHeight = activePage.css("min-height");
      activePage.css("min-height", currentHeight - 1);
    }, 50);
  });
  $(window).trigger("throttledresize");


  var itemID = window.location.pathname.replace("/", ""); //Common.Window.getQueryVariable('i');
  return Wallet.loadWallet(itemID);
});




var Wallet = {
  poweredByWalletIncURL: "https://get.wallet.inc/free-wallet?fpr=jt",

  currentlyLoadedGoogleAnalyticsID: "",
  currentlyFacebookPixelID: "",

  customerAuthenticationToken: "",
  sessionID: null,

  //these items should be set, regardless of entry point.
  itemID: '',
  customerCell: '',
  memberID: '',

  optInSourceID: '',
  optInListID: '',

  isScaffoldingVisible: false,
  Merchant: null,

  //These settings can be set to override the normal behavior of the wallet interface.
  OverrrideSettings: {
    forceMenuHidden: false,
  },

  loadWallet: function (itemID, callback, isRefresh) {
    $("#btnLogin").removeClass("ui-btn").removeClass("ui-shadow").removeClass("ui-corner-all")
    //Reset chat channel, so the user connects to the new company correctly.
    Wallet.Chat.channel = undefined;
    Wallet.Chat.chatSession = undefined;

    if (!Wallet.Page.isEventListenersBound) {
      Wallet.Page.bindEventListeners();
      Wallet.Page.isEventListenersBound = true;
    }

    Wallet.Page.reset();


    $('#wallet').fadeIn();
    $('html, body').animate({ scrollTop: 0 }, 'fast');
    if (itemID) {
      Wallet.itemID = itemID;

      try {
        var navigatorAgent = (navigator.userAgent || undefined);
        //console.time('fetchItem');

        if (window && window.frameElement && window.frameElement.getAttribute("data-scaffolding") === "hide") {
          Wallet.Page.hideScaffolding();
        } else {
          Wallet.Page.showScaffolding();
          Wallet.GDPRCookie.check();
        }

        //show templates
        if (itemID === Common.walletPaymentObjectTemplateID) {
          return Wallet.PaymentObject.renderPaymentObjectTemplate();
        }
        else if (itemID === Common.walletConfigurationTemplateID) {
          return Wallet.PaymentObject.renderWalletConfigurationTemplate();
        }

        var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
        var dataObj = {
          referrer: document.referrer
        };

        if (phoneVerificationToken) {
          dataObj.phoneVerificationToken = phoneVerificationToken;
        }

        if (isRefresh) {
          dataObj.isRefresh = isRefresh;
        }


        Common.Http.makeGET("/wallet/item/identify/" + itemID, dataObj)
          .done(function (result) {

            Wallet.Configuration = result.WalletConfiguration;
            Wallet.Merchant = result.Merchant;

            if (!BrowserInfo.isMobile() && !Common.Window.inIframe()) {
              if (Wallet.Configuration.nonMobileRedirectURL) {
                window.location.href = Wallet.Configuration.nonMobileRedirectURL;
                return;
              }
              if (!window.location.origin) {
                window.location.origin = window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
              }
              window.location.href = window.location.origin + "/i" + window.location.pathname;
              return;
            }

            Wallet.WebSocket.initialize();

            if (result.WalletConfiguration.isAgeGate) {
              Wallet.AgeGate.check(result.WalletConfiguration.ageGateMinimum);
            }

            Wallet.Configuration = result.WalletConfiguration;
            Wallet.Merchant = result.Merchant;
            Wallet.Page.pushState(result);
            Wallet.Page.loadDeferredItems(result.Merchant);
            Wallet.Page.setAppIcons(result);


            if (Common.Cookie.get("PhoneVerificationToken")) {
              //If we're on a custom white-labeled domain, do not fade in the multiple merchant menu.
              if (Wallet && Wallet.Configuration && Wallet.Configuration.customDomain && Wallet.Configuration.customDomain === window.location.host) {
                $("#iconPhoneVerified").fadeOut(0);
              }
              else {
                $("#iconPhoneVerified").fadeIn(0);
              }

              $(".locked-item").fadeOut(0)
            }

            Wallet.Menu.setIndicators(result);


            switch (result.FunctionalType) {
              case 'AdvertisementCreditScan':
                Wallet.AdvertisementCredit.load(result);
                break;
              case 'StaticVoucher':
                Wallet.StaticVoucher.load(result);
                break;
              case 'MembershipTierRedeemable':
                Wallet.MembershipTierRedeemable.load(result);
                break;
              case 'MembershipPoints':
                Wallet.MembershipPoints.load(result);
                break;
              case 'MerchantCredit':
                Wallet.MerchantCredit.load(result);
                break;
              case 'DynamicVoucher':
                Wallet.DynamicVoucher.load(result, isRefresh);
                break;
              case 'NewsArticles':
                Wallet.NewsArticles.load(result);
                break;
              case 'Performances':
                Wallet.Performances.load(result);
                break;
              case 'PromoCodes':
                Wallet.PromoCodes.load(result);
                break;
              case 'LinkBook':
                Wallet.LinkBook.load(result);
                break;
              case 'ImageGrid':
                Wallet.ImageGrid.load(result);
                break;
              case 'Videos':
                Wallet.Videos.load(result);
                break;
              case 'WalletConfiguration':
                Wallet.WalletConfiguration.load(result);
                break;
              case 'Representatives':
                Wallet.Representatives.load(result);
                break;
              case 'MapDirections':
                Wallet.MapDirections.load(result);
                break;
              case 'SmsOptIn':
                Wallet.SmsOptIn.load(result);
                break;
              case 'SmsOptInTemplate':
                Wallet.SmsOptIn.load(result);
                break;
              case 'SmsOptOut':
                Wallet.SmsOptOut.load(result);
                break;
              case 'VirtualBusinessCard':
                Wallet.VirtualBusinessCard.load(result);
                break;
              case 'Products':
                Wallet.Products.load(result);
                break;
              case 'Services':
                Wallet.Services.load(result);
                break;
              case 'RoomRates':
                Wallet.RoomRates.load(result);
                break;
              case 'Amenities':
                Wallet.Amenities.load(result);
                break;
              case 'Gaming':
                Wallet.Gaming.load(result);
                break;
              case 'Dining':
                Wallet.Dining.load(result);
                break;
              case 'Lounges':
                Wallet.Lounges.load(result);
                break;
              case 'Tickets':
                Wallet.Tickets.load(result);
                break;
              default:
                Wallet.Page.clearLoadingMessage();
                Wallet.PaymentObject.showError();
            }

            Wallet.Menu.load();
            Wallet.Page.applyWhiteLabeling();

            Integrations.Google.Analytics.load();

            Integrations.Facebook.Pixel.load();

            Wallet.Page.applyCustomJS();
            Wallet.Page.applyCustomCSS();

            //console.timeEnd('fetchItem');
            if (Common.isFunction(callback)) callback();
          })
          .fail(function (err) {
            Wallet.Page.clearLoadingMessage();
            Wallet.PaymentObject.showError();
            if (Common.isFunction(callback)) callback();
          });
      } catch (err) {
        if (Common.isFunction(callback)) callback();
      }
    } else {
      Wallet.PaymentObject.showError('Page location appears to be invalid.');
      Wallet.Page.clearLoadingMessage();
      if (Common.isFunction(callback)) callback();
    }
  },

  launchExternalLink: function (url) {
    url = url + ''; //ensure it's a string;
    if (!url || url.length == 0) return;

    var pagePrefix = window.location.pathname.substring(1, 3);
    var pageName = "";
    try {
      var data = {
        merchantID: Wallet.Configuration.merchantID,
        exitURL: url,
        pagePrefixType: pagePrefix,
        pageExitingURL: window.location.pathname.substring(1)
      };
      var blob = new Blob([JSON.stringify(data)], { type: "application/json" });
      navigator.sendBeacon(Common.getAPIUrl() + "/wallet/exitLinkClick", blob)

      if (url.includes("tel:")) {
        Integrations.Google.Analytics.trackEvent('event', 'call_initiated');
      }
      else if (url.includes("mailto:")) {
        Integrations.Google.Analytics.trackEvent('event', 'email_initiated');
      }
      else {
        Integrations.Google.Analytics.trackEvent('event', 'exit_link_clicked');
      }

      pageName = Common.Prefixes.convertToName(pagePrefix).toLowerCase().replaceAll(" ", "_");
    }
    catch (err) {
      console.log("Unable to send beacon: " + err)
    }

    //before we append a querystring, check if one already exists by searching for "?" character
    var appendingCharacter = (url.indexOf("?") === -1 ? "?" : "&");

    //preserve any existing UTM parameters before attaching wallet UTMs, so we don't overwrite theirs.
    var source = (url.indexOf("utm_source") === -1 ? "utm_source=wallet" : "");
    var medium = (url.indexOf("utm_medium") === -1 ? "&utm_medium=organic" : "");
    var campaign = (url.indexOf("utm_campaign") === -1 ? "&utm_campaign=" + pageName : "");
    var content = (url.indexOf("utm_content") === -1 ? "&utm_content=link" : "");

    //only append UTMs to websites, ignore all other protocols (e.g. "mailto:", "tel:", "sms:", etc)
    //also, exclude any tiny links that have been used for tracking link clicks
    if ((url.indexOf("https://") > -1 || url.indexOf("http://") > -1) && url.indexOf(window.origin + "/MU") == -1) {
      url = url + appendingCharacter + source + medium + campaign + content;
    }

    window.open(url, "_blank")
  },
  Animations: {
    pageChange: function (btnName) {
      /*
      $(window).scrollTop()
      Promise.resolve()
        .then(() => $("#btnLeftNavMenu").click())
        .then(() => delay(500))
        .then(() => $("#" + btnName).addClass("animate-menu-blink"))
        .then(() => delay(500))
        .then(function () {
          var btn = document.getElementById(btnName)
          var distance = btn.getBoundingClientRect().top + 100 //buffer, so it scrolls into middle of frame
          if (distance > window.innerHeight) {
            return window.scrollTo({
              top: distance,
              behavior: "smooth"
            })
          }
          return;
        })
        .then(() => delay(1500))
        .then(() => $("#" + btnName).removeClass("animate-menu-blink"))
        .then(() => delay(100))
        .then(() => $("#" + btnName).click())
        */
    },
  },

  Configuration: {
    id: '',
    merchantID: '',

    headerBackgroundColor: "",
    headerButtonColor: "",
    leftMenuSectionColor: "",
    leftMenuArrowColor: "",

    companyLogoURL: "",

    isAdCredits: false,
    isAppleEnabled: false,
    isCall: false,
    isChatRoom: false,
    isDynamicVouchers: false,
    isGiftCards: false,
    isGiftCertificates: false,
    isGoogleEnabled: false,
    isImageGrid: false,
    isVideos: false,
    isLinkBook: false,
    isMembershipLevel: false,
    isMembershipPoints: false,
    isMembershipTier: false,
    isMerchantCredit: false,
    isMessages: false,
    isNewsArticles: false,
    isPerformances: false,
    isProfile: false,
    isPromotions: false,
    isSamsungEnabled: false,
    isSettings: false,
    isStaticVouchers: false,
    isTickets: false,
    isTransactionHistory: false,

    publicChatRoomChannelID: "",
    googleAnalyticsID: "",
    facebookPixelID: "",
  },

  Page: {
    isEventListenersBound: false,
    numFilesLoaded: 0,
    pageCallback: null,
    bindEventListeners: function () {
      Wallet.watch("memberID", function (id, oldval, newval) {
        console.log('MemberID has changed from "' + oldval + '" to "' + newval + '"');
        setTimeout(function () {
          Wallet.Menu.load();
        }, 500);
        return newval;
      });

      $(".ui-panel-dismiss").appendTo("#wallet");

      $("#btnLogin").on("click", function (event, ui) {
        Wallet.Login.load();
      });


      $("#menu").on("panelclose", function (event, ui) {
        $("body").removeClass("noscroll")
      });
      $("#merchantsMenu").on("panelclose", function (event, ui) {
        $("body").removeClass("noscroll")
      });

      $("#btnAdCredits").on("click", function () {
        Wallet.AdvertisementCredit.load();
      });

      $("#btnStaticVouchers").on("click", function () {
        Wallet.StaticVoucher.load();
      });
      $("#btnDynamicVouchers").on("click", function () {
        //todo: check whether merchant has any active dynamic voucher campaigns
        Wallet.DynamicVoucher.load();
      });
      $("#btnMembershipTier").on("click", function () {
        Wallet.MembershipTierRedeemable.load();
      });
      $("#btnMembershipPoints").on("click", function () {
        Wallet.MembershipPoints.load();
      });
      $("#btnMembershipLevel").on("click", function () {
        Wallet.MembershipLevel.load();
      });
      $("#btnGiftCards").on("click", function () {
        Wallet.GiftCards.load();
      });
      $("#btnGiftCertificates").on("click", function () {
        Wallet.GiftCertificates.load();
      });
      $("#btnNews").on("click", function () {
        Wallet.NewsArticles.load();
      });
      $("#btnPromotions").on("click", function () {
        Wallet.PromoCodes.load();
      });
      $("#btnLinkBook").on("click", function () {
        Wallet.LinkBook.load();
      });
      $("#btnImageGrid").on("click", function () {
        Wallet.ImageGrid.load();
      });
      $("#btnVideos").on("click", function () {
        Wallet.Videos.load();
      });
      $("#btnMerchantCredit").on("click", function () {
        Wallet.MerchantCredit.load();
      });
      $("#btnTickets").on("click", function () {
        Wallet.Tickets.load();
      });
      $("#btnPerformances").on("click", function () {
        Wallet.Performances.load();
      });
      $("#btnChat").on("click", function () {
        Wallet.Chat.load();
      });
      $("#btnRoom").on("click", function () {
        Wallet.Room.load();
      });
      $("#btnCall").on("click", function () {
        Wallet.Contact.load();
      });
      $("#btnRepresentatives").on("click", function () {
        Wallet.Representatives.load();
      });
      $("#btnProducts").on("click", function () {
        Wallet.Products.load();
      });
      $("#btnServices").on("click", function () {
        Wallet.Services.load();
      });
      $("#btnRoomRates").on("click", function () {
        Wallet.RoomRates.load();
      });
      $("#btnAmenities").on("click", function () {
        Wallet.Amenities.load();
      });
      $("#btnGaming").on("click", function () {
        Wallet.Gaming.load();
      });
      $("#btnDining").on("click", function () {
        Wallet.Dining.load();
      });
      $("#btnLounges").on("click", function () {
        Wallet.Lounges.load();
      });
      $("#btnMapDirections").on("click", function () {
        Wallet.MapDirections.load();
      });
      $("#btnTransactionHistory").on("click", function () {
        Wallet.Transactions.load();
      });
      $("#btnProfile").on("click", function () {
        Wallet.Profile.load();
      });
      $("#btnSettings").on("click", function () {
        Wallet.removeVerification();
        //Wallet.Settings.load();
      });



      //nav button
      $("#btnSmsOpt").on("click", function () {
        if (Wallet.Configuration.smsOptInSourceID === "0") {
          Wallet.SmsOptIn.load(Common.Prefixes.SmsOptInTemplate + Wallet.Configuration.merchantID);
        }
        else {
          window.location.href = "/" + Common.Prefixes.SmsOptIn + Wallet.Configuration.smsOptInSourceID;
        }
      });
      $("#btnEmailSubscriber").on("click", function () {
        Wallet.EmailSubscribe.load();
      });



      //opt-IN button (subscribing function)
      $("#btnSmsOptIn").on("click", function () {

        if (!$("#txtOptInNumber").val()) {
          $("#txtOptInNumber").addClass("required-checkbox");
        }
        else {
          $("#txtOptInNumber").removeClass("required-checkbox");
        }

        if (!$("#chkSmsAgree").prop("checked")) {
          $(".ui-checkbox").addClass("required-checkbox");
        }
        else {
          $(".ui-checkbox").removeClass("required-checkbox");
        }

        if ($("#txtOptInNumber").val() && $("#chkSmsAgree").prop("checked")) {
          Wallet.SmsOptIn.subscribe();
        }
      });
      $("#btnEmailSubscribeSubmit").on("click", function () {
        Wallet.EmailSubscribe.subscribe();
      });


      $("#btnAuthenticatePhoneNumber").on("click", function () {
        Wallet.Authenticate.phoneNumber();
      });
      $("#btnAuthenticateOTP").on("click", function () {
        Wallet.Authenticate.oneTimePasscode();
      });



      $("#btnChatSendMessage").on("click", function () {
        Wallet.Chat.sendMessage();
      })
      $("#btnRoomSendMessage").on("click", function () {
        Wallet.Room.sendMessage();
      })


      $("#btnRepresentativesToLiveChatPage").on("click", function () {
        Wallet.Chat.load();
      })


      /*
      $("#btnSubmit").on("click", function () {
        Admin.UI.LoadingMessage.show("Creating session...");

        var value = $("#txtAmount").val();
        if (isNaN(value) || value < 0) {
          Admin.UI.Notifications.Warning.show("Attention!", "Value must be a positive number.", 3000);
          return;
        }

        Common.Http.makePOST("/stripe/session/create", {
          amount: value,
          cellPhone: $("#txtCellPhone").val(),
          memberID: $("#txtMemberID").val(),
          email: $("#txtEmail").val()
        })
          .done(function (session) {
            Admin.UI.LoadingMessage.hide();
            console.log(JSON.stringify(session));

            if (!session || !session.id) {
              Admin.UI.Notifications.Danger.show("Error:", "An unexpected error occurred when creating your session. Please try again.", 4000);
            }
            else {

              var stripe = Stripe(GiftCertificate.publicKey);
              stripe.redirectToCheckout({ sessionId: session.id })
                .then(function (result) {
                  Admin.UI.Notifications.Danger.show("Error:", result.error.message, 5000);
                  // If `redirectToCheckout` fails due to a browser or network
                  // error, display the localized error message to your customer
                  // using `result.error.message`.
                });
            }

          })
          .fail(function (err) {
            Admin.UI.LoadingMessage.hide();
            Admin.UI.Notifications.renderHttpFailure(err);
          });

      });
      */

    },
    reset: function () {
      Wallet.Menu.close();
      Wallet.Page.showLoadingMessage();
      Wallet.Merchants.close();

      //Set payment object back to its default values
      Wallet.memberID = "";
      Wallet.customerCell = "";


      Wallet.PaymentObject.startingAmount = 0;
      Wallet.PaymentObject.currentAmount = 0;
      Wallet.PaymentObject.value = 0;
      Wallet.PaymentObject.valueType = 0;
      Wallet.PaymentObject.valueToDisplay = 0;
      Wallet.PaymentObject.faceValue = 0;
      Wallet.PaymentObject.Design = {};

      //loader.show();

      $('#divAmount').css('font-size', '55px');

      $("#paymentContainer").fadeOut(0);
      $("#paymentObject").fadeOut(0);
      $("#controlGroupButtons").fadeOut(0);
      $("#collapsibleCoupons").fadeOut(0);

      $('#collapsibleEvents').empty();
      $("#collapsibleEvents").fadeOut(0);

      $('#divVerticalList').empty();
      $("#divVerticalList").fadeOut(0);

      $("#divNoneToDisplay").fadeOut(0);
      $("#divNoneToDisplay").removeClass("align-center");
      $('#divAmount').html("").fadeOut(0);
      $('#divDetails').html("").fadeOut(0);
      $('#divRedeemed').html("").fadeOut(0);
      $('#paymentUnusable').html("").fadeOut(0);
      $('#divExpires').attr('style', '').fadeOut(0);
      $('#divBarcodeTarget').attr('style', '').fadeOut(0);
      $('#paymentObject').css('opacity', 1);

      $("#divSupplementalInfo").fadeOut(0)
      $("#divSupplementalInfo").html("")
      $("#btnClaimTicket").fadeOut(0)
      $("#btnClaimTicket").off("click");

      $("#divDebuggingInfo").fadeOut(0)
      $("#divDebuggingInfo").html("")

      $("#divPages").children().fadeOut(0);
      $("#placeholder").fadeIn(0);
      $("#divTransactionsDetails").empty();

      $("#divEmailSubscribeSubmitted").fadeOut(0);
      $("#divEmailSubscribeError").fadeOut(0);

      $("#btnRepresentativesToLiveChatPage").fadeOut();

      $("#scriptCustomJS").empty();
      $("#styleCustomCSS").empty();

      $('#divDownloadOnAppStore').unbind('click');
      $('#divDownloadOnGooglePlay').unbind('click');


      Wallet.DynamicVoucher.clearPollingMechanism()

      Integrations.Apple.Wallet.hideLink();
    },
    loadDeferredItems: function (merchant) {
      if ($("#metaDescription").length === 0) {
        $("head").append("<meta name='description' content='Digital Wallet for " + merchant.companyName + ", provided by Wallet Inc.'>")
      }
      setTimeout(function () {
        if ($("#imgAuthenticateForRewards").attr("src") === undefined) {
          $("#imgAuthenticateForRewards").attr("src", "/img/wallet-ui/authenticate-for-rewards-reduced.png")
        }
        $("#imgPoweredByWalletInc").attr("src", "/img/wallet-logo.svg");
        $("#aPoweredByWalletLogo").attr("href", "https://get.wallet.inc/free-wallet?fpr=jt&utm_source=wallet-inc&utm_medium=wallet-ui&utm_term=logo-click&utm_content=" + merchant.id + "&utm_campaign=free-wallet&utm_source_platform=proprietary&utm_creative_format=app&utm_marketing_tactic=bof")
      }, 2000)

      if (Wallet.Page.inIframe() && Wallet.Configuration.customDomain && !Wallet.Configuration.isClaimed) {
        $('#trClaimAccount', parent.document).fadeIn(0);
      }
    },

    setAppIcons: function (configuration) {
      try {
        $("#linkFavicon").remove();
        $("#linkAppleIcon").remove();
        $("#linkIcon1").remove();
        $("#linkIcon2").remove();
        if (Wallet.Page.inIframe()) {
          $("#linkFavicon", parent.document).remove();
          $("#linkAppleIcon", parent.document).remove();
          $("#linkIcon1", parent.document).remove();
          $("#linkIcon2", parent.document).remove();
        }
        if (configuration && configuration.WalletConfiguration && configuration.WalletConfiguration.mobileAppIconURL) {
          if (Wallet.Page.inIframe()) {
            $("head", parent.document).append('<link id="linkAppleIcon" rel="apple-touch-icon" href="' + configuration.WalletConfiguration.mobileAppIconURL + '"/>');
            $('head', parent.document).append('<link id="linkFavicon" rel="shortcut icon" href="' + Wallet.Configuration.mobileAppIconURL + '" />');
          }
          else {
            $("head").append('<link id="linkAppleIcon" rel="apple-touch-icon" href="' + configuration.WalletConfiguration.mobileAppIconURL + '"/>');
            $('head').append('<link id="linkFavicon" rel="shortcut icon" href="' + Wallet.Configuration.mobileAppIconURL + '" />');
          }
        }
        else {
          if (Wallet.Page.inIframe()) {
            $("head", parent.document).append('<link id="linkAppleIcon" rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>');
            $("head", parent.document).append('<link id="linkIcon1" rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png"/>');
            $("head", parent.document).append('<link id="linkIcon2" rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png"/>');
          }
          else {
            $("head").append('<link id="linkAppleIcon" rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png"/>');
            $("head").append('<link id="linkIcon1" rel="icon" type="image/png" sizes="32x32" href="/img/favicon-32x32.png"/>');
            $("head").append('<link id="linkIcon2" rel="icon" type="image/png" sizes="16x16" href="/img/favicon-16x16.png"/>');
          }
        }
      }
      catch (err) {
        console.error("An error occurred when setting the favicon.")
      }
    },


    showLoadingMessage: function (text) {
      Wallet.Page.isLoading = true;
      $("#btnLeftNavMenu").css("opacity", "0.5");
      //loader.show();
      $.mobile.loading("show", {
        text: (text ? text : "Loading..."),
        textVisible: true,
        textonly: false,
      });
    },
    clearLoadingMessage: function () {
      Wallet.Page.isLoading = false;
      $("#btnLeftNavMenu").css("opacity", "1");
      $("#placeholder").fadeOut(0);
      $.mobile.loading("hide");

      //loader.hide();
    },
    pushState: function (object) {
      //here, we set the browser history to contain this new voucher, so the user can navigate back/forth between their offers after viewing
      try {
        //if the new state is the same as the old state, then skip this step so we don't have duplicates in the browser history.
        if (
          object &&
          object.ID &&
          window.history &&
          window.history.state &&
          window.history.state.pageData &&
          window.history.state.pageData.ID === object.ID
        ) return true;

        //var url = window.location.pathname
        var url = object.ID

        if (object.PageTitle) {
          document.title = object.Merchant.companyName + ": " + object.PageTitle;
        }
        else {
          document.title = object.Merchant.companyName + ": " + object.FunctionalType.match(/[A-Z][a-z]+|[0-9]+/g).join(" ");
        }


        if (Common.Window.inIframe() && window.parent && window.parent.document && window.parent.document.body && $(window.parent.document.body).hasClass("wallet-desktop-frame")) {
          //window.parent.history.pushState({ 'pageData': object, 'pageTitle': document.title }, '', url + window.location.search);
        }
        else {
          window.history.pushState({ 'pageData': object, 'pageTitle': document.title }, '', url + window.location.search);
        }

      } catch (err) {
        console.error('error: ' + JSON.stringify(err));
        //unable to push the new coupon into the browser history. not much else to do here.
      }
    },
    inIframe: function () {
      try {
        return window.self !== window.top;
      } catch (e) {
        return true;
      }
    },
    hideScaffolding: function () {
      Wallet.isScaffoldingVisible = false;
      $('#walletHeader').fadeTo(0, 0);
      $('#walletFooter').fadeTo(0, 0);
      $('#walletHeader').css('min-height', '0px');
      $('#walletHeader').css('height', '0px');
      $('#walletFooter').css('height', '0px');

      $("#wallet").css("padding-top", "0px");
    },
    showScaffolding: function () {
      Wallet.isScaffoldingVisible = true;
      $('#walletHeader').fadeTo(0, 100);
      $('#walletHeader').css('min-height', '52px');

      $("#wallet").css("padding-top", "58px");
    },
    applyWhiteLabeling: function () {
      if (Wallet.isScaffoldingVisible && Wallet.Merchant && Wallet.Merchant.isWhiteLabeled === false && Wallet.Merchant.isOnBillablePlan === false) {
        $('#walletFooter').fadeTo(0, 100);
      }
    },

    applyCustomJS: function () {
      $("#scriptCustomJS").empty();
      if (Wallet.Configuration.customJS) {
        $("#scriptCustomJS").append("\ntry{\n" + Wallet.Configuration.customJS + "\n}\ncatch(error){\nconsole.error('There is an error in your custom Javascript code. Please review and fix.');\n}\n");
        console.log("Custom JS applied.");
      }
    },
    applyCustomCSS: function () {
      $("#styleCustomCSS").empty();
      if (Wallet.Configuration.customCSS) {
        $("#styleCustomCSS").append("\n" + Wallet.Configuration.customCSS + "\n");
        console.log("Custom CSS applied.");
      }
    },

    resizeCompanyNameToFit: function (newSize) {
      if (!newSize) newSize = '55px';
      var tempDiv = $('<div></div>').css('font-size', newSize).css('visibility', 'hidden').css('display', 'none');
      tempDiv.html($('#companyLogoContainer').html())
      tempDiv.attr('id', "divVirtualTextMeasurement2")
      $("body").append(tempDiv)

      var paddingLeft = parseInt($('#paymentObject').css("padding-left"));
      var paddingRight = parseInt($('#paymentObject').css("padding-right"));
      if ((tempDiv.width() + paddingLeft + paddingRight) >= $('#paymentObject').width()) {
        $("body").remove("#divVirtualTextMeasurement2");

        var fontsize = $('#companyLogoContainer').css('font-size');
        var newSize = parseFloat(fontsize) - 1;
        $('#companyLogoContainer').css('font-size', newSize);
        if (newSize > 16)
          Wallet.Page.resizeCompanyNameToFit(newSize);
      }
    },

    resizeCompanyHeaderToFit: function (newSize) {
      if (!newSize) newSize = '55px';
      var tempDiv = $('<div></div>').css('font-size', newSize).css('visibility', 'hidden').css('display', 'none');
      tempDiv.html($('#divCompanyName').html())
      tempDiv.attr('id', "divVirtualTextMeasurement3")
      $("body").append(tempDiv)

      var paddingLeft = parseInt($('.no-padding-page .wallet-page-header').css("padding-left"));
      var paddingRight = parseInt($('.no-padding-page .wallet-page-header').css("padding-right"));
      var marginLeft = parseInt($('#divCompanyName').css("margin-left"));
      var marginRight = parseInt($('#divCompanyName').css("margin-right"));
      if ((tempDiv.width()) >= ($('#divWalletConfiguration').width() - paddingLeft - paddingRight - marginLeft - marginRight - $("#custom-icon").width())) {
        $("#divVirtualTextMeasurement3").remove();

        var fontsize = $('#divCompanyName').css('font-size');
        var newSize = parseFloat(fontsize) - 1;
        $('#divCompanyName').css('font-size', newSize);
        if (newSize > 16) {
          Wallet.Page.resizeCompanyHeaderToFit(newSize);
        }
      }
      else {
        $("#divVirtualTextMeasurement3").remove();
      }
    },

  },

  Menu: {
    load: function () {

      //Header Backgruond & Font Color
      $("#walletHeader").css("background-color", Wallet.Configuration.headerBackgroundColor || "#ffffff");
      $("#walletHeader").css("color", Wallet.Configuration.headerButtonColor || "#3388cc");

      if ($("#metaThemeHeader").length === 0) {
        $("head").append('<meta id="metaThemeHeader" name="theme-color" content="' + Wallet.Configuration.headerBackgroundColor + '">');
      }
      else {
        $("#metaThemeHeader").attr("content", Wallet.Configuration.headerBackgroundColor || "#ffffff");
      }


      //Section Header, Background & Font Color
      $("#menuNeedHelp").css('background-color', Wallet.Configuration.leftMenuHeaderBackgroundColor || "#ffffff");
      $("#menuNeedHelp").css('color', Wallet.Configuration.leftMenuHeaderFontColor || "#3388cc");

      //Section Background Color
      $("#menuItems").css('background-color', Wallet.Configuration.leftMenuSectionBackgroundColor || "#ffffff");
      $("#divMenuWelcomeSection").css('background-color', Wallet.Configuration.leftMenuSectionBackgroundColor || "#ffffff");

      //Section Font Color
      $(".left-nav-menu-items a").css('color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");
      $("#divMenuWelcomeSection").css('color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");
      $("#divMenuWelcomeSection a").css('color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");
      $("#btnTransactionHistory i").css('border-color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");
      $("#btnSettings i").css('border-color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");
      $("#walletFooter").css('color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");
      $(".help-icons").css('color', Wallet.Configuration.leftMenuSectionFontColor || "#464646");


      if (localStorage.getItem("EmailSubscribe-" + Wallet.Configuration.merchantID)) {
        $("#bubbleEmailSubscribe").fadeIn();
      }
      if (localStorage.getItem("SmsSubscribe-" + Wallet.Configuration.merchantID)) {
        $("#bubbleSmsSubscribe").fadeIn();
      }


      if (Wallet.Configuration.companyLogoURL) {

        if ($("#imgHeaderCompanyLogo").length === 0 || $("#imgHeaderCompanyLogo").attr("src") != Wallet.Configuration.companyLogoURL) {
          $("#companyLogoContainer").html('<img id="imgHeaderCompanyLogo" height="50" width="209" src="' + Wallet.Configuration.companyLogoURL + '" />');
          $("#imgHeaderCompanyLogo").attr("src", Wallet.Configuration.companyLogoURL);
        }

        var companyName = Wallet.Merchant && Wallet.Merchant.companyName ? Wallet.Merchant.companyName : "";
        if (companyName) {
          $("#imgHeaderCompanyLogo").attr("alt", companyName + "'s Digital Wallet, Powered by Wallet Inc.");
        }
        else {
          $("#imgHeaderCompanyLogo").attr("alt", "Digital Wallet, Powered by Wallet Inc.");
        }
        $("#imgHeaderCompanyLogo").fadeIn();

        $("#imgMerchantLogoUnlock").attr("src", Wallet.Configuration.companyLogoURL).fadeIn(0)

      } else {
        $("#imgMerchantLogoUnlock").attr("src", "").fadeOut(0)
        var companyName = Wallet.Merchant && Wallet.Merchant.companyName ? Wallet.Merchant.companyName : "";
        $("#companyLogoContainer").html(companyName);
        Wallet.Page.resizeCompanyNameToFit();
      }



      if (Common.Cookie.get("PhoneVerificationToken") && Common.Cookie.get("CustomerMobileNumber")) {
        $("#btnLogin").hide();
        $("#btnSettings").children().show();
        if (Wallet.Configuration.isTransactionHistory) {
          $("#btnTransactionHistory").children().show()
        }
        else {
          $("#btnTransactionHistory").children().hide()
        }
        //$("#divWelcomeText").html(Common.Cookie.get("CustomerMobileNumber") + ' ' + Common.Icons.generate("shield-check"))
        Wallet.fetchMerchants();
      }
      else {
        $("#btnLogin").show();
        $("#btnSettings").children().hide();
        $("#btnTransactionHistory").children().hide();
      }


      //visit
      if (
        !Wallet.Configuration.isGaming &&
        !Wallet.Configuration.isDining &&
        !Wallet.Configuration.isLounges &&
        !Wallet.Configuration.isRoomRates &&
        !Wallet.Configuration.isAmenities &&
        !Wallet.Configuration.isProducts &&
        !Wallet.Configuration.isServices &&
        !Wallet.Configuration.isNewsArticles &&
        !Wallet.Configuration.isPerformances &&
        !Wallet.Configuration.isImageGrid &&
        !Wallet.Configuration.isVideos &&
        !Wallet.Configuration.isLinkBook
      ) {
        $("#menuVisit").fadeOut(0);
        $("#menuVisit").next().fadeOut(0);
      }
      else {
        $("#menuVisit").fadeIn(0);
        $("#menuVisit").next().fadeIn(0);
      }
      (Wallet.Configuration.isGaming ? $("#btnGaming").fadeIn() : $("#btnGaming").fadeOut());
      (Wallet.Configuration.isDining ? $("#btnDining").fadeIn() : $("#btnDining").fadeOut());
      (Wallet.Configuration.isLounges ? $("#btnLounges").fadeIn() : $("#btnLounges").fadeOut());
      (Wallet.Configuration.isProducts ? $("#btnProducts").fadeIn() : $("#btnProducts").fadeOut());
      (Wallet.Configuration.isServices ? $("#btnServices").fadeIn() : $("#btnServices").fadeOut());
      (Wallet.Configuration.isRoomRates ? $("#btnRoomRates").fadeIn() : $("#btnRoomRates").fadeOut());
      (Wallet.Configuration.isAmenities ? $("#btnAmenities").fadeIn() : $("#btnAmenities").fadeOut());
      (Wallet.Configuration.isNewsArticles ? $("#btnNews").fadeIn() : $("#btnNews").fadeOut());
      (Wallet.Configuration.isPerformances ? $("#btnPerformances").fadeIn() : $("#btnPerformances").fadeOut());
      (Wallet.Configuration.isImageGrid ? $("#btnImageGrid").fadeIn() : $("#btnImageGrid").fadeOut());
      (Wallet.Configuration.isVideos ? $("#btnVideos").fadeIn() : $("#btnVideos").fadeOut());
      (Wallet.Configuration.isLinkBook ? $("#btnLinkBook").fadeIn() : $("#btnLinkBook").fadeOut());


      //subscribe
      if (
        !Wallet.Configuration.isSmsOptIn &&
        !Wallet.Configuration.isEmailSubscriber
      ) {
        $("#menuSubscribe").fadeOut(0);
        $("#menuSubscribe").next().fadeOut(0);
      }
      else {
        $("#menuSubscribe").fadeIn(0);
        $("#menuSubscribe").next().fadeIn(0);
      }
      (Wallet.Configuration.isSmsOptIn ? $("#btnSmsOpt").fadeIn() : $("#btnSmsOpt").fadeOut());
      (Wallet.Configuration.isEmailSubscriber ? $("#btnEmailSubscriber").fadeIn() : $("#btnEmailSubscriber").fadeOut());



      //transact
      if (
        !Wallet.Configuration.isPromotions &&
        !Wallet.Configuration.isAdCredits &&
        !Wallet.Configuration.isStaticVouchers &&
        !Wallet.Configuration.isDynamicVouchers &&
        !Wallet.Configuration.isMerchantCredit &&
        !Wallet.Configuration.isGiftCards &&
        !Wallet.Configuration.isGiftCertificates &&
        !Wallet.Configuration.isTickets
      ) {
        $("#menuCustomer").fadeOut(0);
        $("#menuCustomer").next().fadeOut(0);
      }
      else {
        $("#menuCustomer").fadeIn(0);
        $("#menuCustomer").next().fadeIn(0);
      }
      (Wallet.Configuration.isPromotions ? $("#btnPromotions").fadeIn() : $("#btnPromotions").fadeOut());
      (Wallet.Configuration.isAdCredits ? $("#btnAdCredits").fadeIn() : $("#btnAdCredits").fadeOut());
      (Wallet.Configuration.isStaticVouchers ? $("#btnStaticVouchers").fadeIn() : $("#btnStaticVouchers").fadeOut());
      (Wallet.Configuration.isDynamicVouchers ? $("#btnDynamicVouchers").fadeIn() : $("#btnDynamicVouchers").fadeOut());
      (Wallet.Configuration.isMerchantCredit ? $("#btnMerchantCredit").fadeIn() : $("#btnMerchantCredit").fadeOut());
      (Wallet.Configuration.isGiftCards ? $("#btnGiftCards").fadeIn() : $("#btnGiftCards").fadeOut());
      (Wallet.Configuration.isGiftCertificates ? $("#btnGiftCertificates").fadeIn() : $("#btnGiftCertificates").fadeOut());
      (Wallet.Configuration.isTickets ? $("#btnTickets").fadeIn() : $("#btnTickets").fadeOut());



      //membership
      if (
        !Wallet.Configuration.isMembershipTier &&
        !Wallet.Configuration.isMembershipPoints &&
        !Wallet.Configuration.isMembershipLevel
      ) {
        $("#menuMember").fadeOut(0);
        $("#menuMember").next().fadeOut(0);
      }
      else {
        $("#menuMember").fadeIn(0);
        $("#menuMember").next().fadeIn(0);
      }
      (Wallet.Configuration.isMembershipTier ? $("#btnMembershipTier").fadeIn() : $("#btnMembershipTier").fadeOut());
      (Wallet.Configuration.isMembershipPoints ? $("#btnMembershipPoints").fadeIn() : $("#btnMembershipPoints").fadeOut());
      (Wallet.Configuration.isMembershipLevel ? $("#btnMembershipLevel").fadeIn() : $("#btnMembershipLevel").fadeOut());



      //need help
      if (
        !Wallet.Configuration.isCall &&
        !Wallet.Configuration.isMapDirections &&
        !Wallet.Configuration.isRepresentatives &&
        !Wallet.Configuration.isMessages
      ) {
        $("#menuNeedHelp").fadeOut(0);
      }
      else {
        $("#menuNeedHelp").fadeIn(0);
      }
      (Wallet.Configuration.isCall ? $("#btnCall").fadeIn() : $("#btnCall").fadeOut());
      (Wallet.Configuration.isMapDirections ? $("#btnMapDirections").fadeIn() : $("#btnMapDirections").fadeOut());
      (Wallet.Configuration.isRepresentatives ? $("#btnRepresentatives").fadeIn() : $("#btnRepresentatives").fadeOut());
      (Wallet.Configuration.isMessages ? $("#btnChat").fadeIn() : $("#btnChat").fadeOut());

      Integrations.Apple.Store.addLink();
      Integrations.Google.Store.addLink();

      setTimeout(function () {
        if (
          (
            $("#menuVisit").is(':visible') ||
            $("#menuSubscribe").is(':visible') ||
            $("#menuCustomer").is(':visible') ||
            $("#menuMember").is(':visible') ||
            Wallet.Configuration.isTransactionHistory
          )
          && !Wallet.OverrrideSettings.forceMenuHidden
        ) {
          setTimeout(function () { $("#btnLeftNavMenu").fadeIn(0); }, 100);
        }
        else {
          $("#btnLeftNavMenu").fadeOut(0);
        }
        $("#walletHeader").fadeIn(0);
      }, 300)

    },
    open: function () {
      if (Wallet.Page.isLoading) return;
      $("body").addClass("noscroll")
      $('#menu').panel('open');
      $('#menu').on("panelclose", function (event, ui) {
        $("#divPages").removeClass("lock-page-scrolling")
        $("#wallet").removeClass("content-shift-right")
      });
      var pageHeight = (parseInt($("#walletHeader").height()) + parseInt($("#divPages").height()));
      var menuHeight = parseInt($("#menu").height())
      var resultHeight = (pageHeight > menuHeight ? pageHeight : menuHeight)
      $('.ui-panel-dismiss').attr("style", "height:" + resultHeight + "px")
      $("#divPages").addClass("lock-page-scrolling")
      $('#menu').scrollTop(0)
      $("#wallet").addClass("content-shift-right")
    },
    close: function () {
      $('#menu').on("panelclose", function (event, ui) {
        $("#divPages").removeClass("lock-page-scrolling")
        $("#wallet").removeClass("content-shift-right")
      });
      $('#menu').panel('close');
      $('#menu').scrollTop(0)
    },
    setIndicators: function (dataObj) {
      if (!dataObj) return;

      if (!dataObj.NavItemCounts) return;

      if (typeof dataObj.NavItemCounts.promoCodes != "undefined") {
        Wallet.Menu.setNotification("bubblePromotions", dataObj.NavItemCounts.promoCodes)
        $("#bubblePromotions").fadeIn(0)
      }
      if (typeof dataObj.NavItemCounts.dynamicVouchers != "undefined") {
        Wallet.Menu.setNotification("bubbleDynamicVouchers", dataObj.NavItemCounts.dynamicVouchers)
        $("#bubbleDynamicVouchers").fadeIn(0)
      }

      var adCreditsCount = 0;
      var adCreditsString = localStorage.getItem("AdCredits-" + Wallet.Configuration.merchantID)
      if (adCreditsString) {
        var adCreditsItems = JSON.parse(adCreditsString)
        adCreditsCount = (adCreditsItems && adCreditsItems.length ? adCreditsItems.length : "0")
      }
      Wallet.Menu.setNotification("bubbleAdCredits", adCreditsCount)
      $("#bubbleAdCredits").fadeIn(0)


      if (typeof dataObj.NavItemCounts.membershipPoints != "undefined") {
        Wallet.Menu.setNotification("bubbleMembershipPoints", dataObj.NavItemCounts.membershipPoints)
        $("#bubbleMembershipPoints").fadeIn(0)
      } else {
        Wallet.Menu.clearNotifications("bubbleMembershipPoints")
      }
      if (typeof dataObj.NavItemCounts.membershipTier != "undefined") {
        Wallet.Menu.setNotification("bubbleMembershipTier", dataObj.NavItemCounts.membershipTier + "%")
        $("#bubbleMembershipTier").fadeIn(0)
      } else {
        Wallet.Menu.clearNotifications("bubbleMembershipTier")
      }
      if (typeof dataObj.NavItemCounts.ticketsCount != "undefined") {
        Wallet.Menu.setNotification("bubbleTickets", dataObj.NavItemCounts.ticketsCount)
        $("#bubbleTickets").fadeIn(0)
      } else {
        Wallet.Menu.clearNotifications("bubbleTickets")
      }
      if (typeof dataObj.NavItemCounts.merchantCredit != "undefined") {
        Wallet.Menu.setNotification("bubbleMerchantCredit", Common.Currencies.get(Wallet.Merchant.currency).symbol + dataObj.NavItemCounts.merchantCredit)
      } else {
        Wallet.Menu.clearNotifications("bubbleMerchantCredit")
      }
      if (typeof dataObj.NavItemCounts.staticVouchersCount != "undefined") {
        Wallet.Menu.setNotification("bubbleStaticVouchers", dataObj.NavItemCounts.staticVouchersCount)
        $("#bubbleStaticVouchers").fadeIn(0)
      } else {
        Wallet.Menu.clearNotifications("bubbleStaticVouchers")
      }


      if (Common.Cookie.get("PhoneVerificationToken")) {
        Wallet.Menu.setNotification("bubbleGiftCards", 0)
        Wallet.Menu.setNotification("bubbleGiftCertificates", 0)
        Wallet.Menu.setNotification("bubbleMembershipLevel", 0)
      }


      if (Common.Cookie.get("PhoneVerificationToken") && dataObj.NavItemCounts.isMember === true) {
        $("#divWelcomeText").html("Welcome, <b>" + (dataObj.NavItemCounts.memberFirstName ? dataObj.NavItemCounts.memberFirstName + "!" : "Member") + "</b>");
        $("#iUserLifecycleStatus").removeClass("fa-user-tag").removeClass("fa-user-check").removeClass("fa-user-circle").addClass("fa-crown")
        $("#iUserLifecycleStatus").removeClass("visitor").removeClass("subscriber").removeClass("customer").addClass("member")
        $(".dot-visitor").removeClass("lifecycle-not-reached");
        $(".dot-subscriber").removeClass("lifecycle-not-reached");
        $(".dot-customer").removeClass("lifecycle-not-reached");
        $(".dot-member").removeClass("lifecycle-not-reached");
      }
      else if (Common.Cookie.get("PhoneVerificationToken")) {
        $("#divWelcomeText").html("Welcome, <b>Customer</b>");
        $("#iUserLifecycleStatus").removeClass("fa-crown").removeClass("fa-user-check").removeClass("fa-user-circle").addClass("fa-user-tag")
        $("#iUserLifecycleStatus").removeClass("visitor").removeClass("subscriber").removeClass("member").addClass("customer")
        $(".dot-visitor").removeClass("lifecycle-not-reached");
        $(".dot-subscriber").removeClass("lifecycle-not-reached");
        $(".dot-customer").removeClass("lifecycle-not-reached");
        $(".dot-member").addClass("lifecycle-not-reached");
      }
      else if (localStorage.getItem("EmailSubscribe-" + Wallet.Configuration.merchantID) || localStorage.getItem("SmsSubscribe-" + Wallet.Configuration.merchantID)) {
        $("#divWelcomeText").html("Welcome, <b>Subscriber</b>");
        $("#iUserLifecycleStatus").removeClass("fa-crown").removeClass("fa-user-tag").removeClass("fa-user-circle").addClass("fa-user-check")
        $("#iUserLifecycleStatus").removeClass("visitor").removeClass("customer").removeClass("member").addClass("subscriber")
        $(".dot-visitor").removeClass("lifecycle-not-reached");
        $(".dot-subscriber").removeClass("lifecycle-not-reached");
        $(".dot-customer").addClass("lifecycle-not-reached");
        $(".dot-member").addClass("lifecycle-not-reached");
      }
      else {
        $("#divWelcomeText").html("Welcome, <b>Visitor</b>");
        $("#iUserLifecycleStatus").removeClass("fa-crown").removeClass("fa-user-tag").removeClass("fa-user-check").addClass("fa-user-circle")
        $("#iUserLifecycleStatus").removeClass("subscriber").removeClass("customer").removeClass("member").addClass("visitor")
        $(".dot-visitor").removeClass("lifecycle-not-reached");
        $(".dot-subscriber").addClass("lifecycle-not-reached");
        $(".dot-customer").addClass("lifecycle-not-reached");
        $(".dot-member").addClass("lifecycle-not-reached");
      }




    },
    setNotification: function (element, value) {
      if (typeof value === "undefined") value = 0;

      $("#" + element).fadeIn();
      $("#" + element).html(value);
    },
    clearNotifications: function (element) {
      $("#" + element).html("");
      $("#" + element).fadeOut(0);
      $("#" + element).parent().find(".locked-item").fadeIn(0)
    },
  },

  Merchants: {
    open: function () {
      $("body").addClass("noscroll")
      $('#merchantsMenu').panel('open');
      $('#merchantsMenu').on("panelclose", function (event, ui) {
        $("#divPages").removeClass("lock-page-scrolling")
      });
      $('#merchantsMenu').scrollTop(0)

      var pageHeight = (parseInt($("#walletHeader").height()) + parseInt($("#divPages").height()) + parseInt($("#walletFooter").height()));
      var menuHeight = parseInt($("#merchantsMenu").height())
      var resultHeight = (pageHeight > menuHeight ? pageHeight : menuHeight)
      $('.ui-panel-dismiss').attr("style", "height:" + resultHeight + "px")
      $('#merchantsMenu').attr("style", "height:" + resultHeight + "px")
      $("#divPages").addClass("lock-page-scrolling")
    },
    close: function () {
      $('#merchantsMenu').on("panelclose", function (event, ui) {
        $("#divPages").removeClass("lock-page-scrolling")
      });
      $('#merchantsMenu').panel('close');
      $('#merchantsMenu').scrollTop(0)
    },
    setNotification: function (element, value) {
    },
    clearNotifications: function (element) {
    }
  },

  PaymentObject: {
    startingAmount: 0,  // only used by DynamicVouchers
    currentAmount: 0,   // only used by DynamicVouchers
    value: 0,           // the number value
    valueType: null,    // ["currency", "percent"]
    valueToDisplay: "", // TEXT DISPLAYED TO USER
    //faceValue: 0,       // A string representation with valueType considered: $20.05 or 20.05%

    Design: {},    //populated by a request to the server

    display: function () {
      $("#paymentContainer").fadeIn(0);
      $("#paymentObject").fadeIn(0);
    },

    //This shows a payment object without the wallet scaffolding (no company header & navigation menus)
    renderPaymentObjectTemplate: function () {
      Wallet.Page.reset();
      Wallet.Page.clearLoadingMessage();
      Wallet.Page.hideScaffolding();

      //set page level values for customer
      Wallet.customerCell = "8001234567";
      Wallet.memberID = "00000001";

      //set PaymentObject properties
      Wallet.PaymentObject.startingAmount = "[Value]";
      Wallet.PaymentObject.currentAmount = "[Value]";
      Wallet.PaymentObject.valueToDisplay = "[Value]";
      Wallet.PaymentObject.valueType = "[Icon]";
      Wallet.PaymentObject.showValue();
      Wallet.PaymentObject.showMemberDetails();
      Wallet.PaymentObject.showValidPeriod("12:00 AM 1/1/2017", "11:59 PM 11/30/2027");


      //good to go, display this baby
      $("#paymentContainer").fadeIn(0);
      $("#paymentObject").fadeIn(0);
      $('#paymentUnusable').fadeOut(0);
      $('#paymentObject').css('opacity', 1);
      $('#wallet').fadeIn();
    },

    //This shows a wallet template with the company header and navigation menu
    renderWalletConfigurationTemplate: function () {
      Wallet.Page.reset();
      Wallet.Page.clearLoadingMessage();

      //set page level values for customer
      Wallet.customerCell = "8001234567";
      Wallet.memberID = "00000001";

      //set PaymentObject properties
      Wallet.PaymentObject.startingAmount = "[Value]";
      Wallet.PaymentObject.currentAmount = "[Value]";
      Wallet.PaymentObject.valueToDisplay = "[Value]";
      Wallet.PaymentObject.valueType = "[Icon]";
      Wallet.PaymentObject.showValue();
      Wallet.PaymentObject.showMemberDetails();
      Wallet.PaymentObject.showValidPeriod("12:00 AM 1/1/2017", "11:59 PM 11/30/2027");


      //good to go, display this baby
      $("#paymentContainer").fadeIn(0);
      $("#paymentObject").fadeIn(0);
      $('#paymentUnusable').fadeOut(0);
      $('#paymentObject').css('opacity', 1);
      $('#btnMerchantsOpen').fadeOut(0);
    },
    displayDesign: function () {
      if (Wallet.PaymentObject.Design && Wallet.PaymentObject.Design.id) {

        var design = Wallet.PaymentObject.Design;
        var displayText = (design && design.icon ? design.icon : "") + " ";
        displayText += (design && design.displayName ? design.displayName : "");

        $('#divType').html(displayText);
        $('#divType').fadeIn();

        $('#paymentObject').css('font-family', Wallet.PaymentObject.Design.fontType);
        $('#paymentObject').css('color', Wallet.PaymentObject.Design.fontColor);
        $('#paymentObject').css('border-radius', Wallet.PaymentObject.Design.borderRadius + 'px');
        $('#paymentObject').css('border',
          Wallet.PaymentObject.Design.borderSize + ' ' +
          Wallet.PaymentObject.Design.borderStyleType + ' ' +
          Wallet.PaymentObject.Design.borderColor);

        if (Wallet.PaymentObject.Design.backgroundImageURL) {
          $('#paymentObject').css('background-image', 'url(\'' + Wallet.PaymentObject.Design.backgroundImageURL + '\')');
          $('#paymentObject').css('background-size', 'cover');
          $('#paymentObject').css('background-clip', 'padding-box');
          $('#paymentObject').css('background-repeat', 'no-repeat');
        }

      }
    },


    getDisplayAmount: function () {
      var displayAmount = "";
      if (isNaN(Wallet.PaymentObject.valueToDisplay)) {
        //if (Wallet.PaymentObject.valueToDisplay != "" && typeof Wallet.PaymentObject.valueToDisplay != "undefined") {
        displayAmount = Wallet.PaymentObject.valueToDisplay;
      } else {
        displayAmount = Common.Integer.getFaceValue(Wallet.PaymentObject.currentAmount, Wallet.PaymentObject.valueType, Common.Currencies.get(Wallet.Merchant.currency).symbol);
      }
      return displayAmount;
    },

    showValueWithInternationalization: function (amount) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
        // These options are needed to round to whole numbers if that's what's required.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      });

      $('#divAmount').fadeOut(function () {
        $('#divAmount').html(formatter.format(amount));
        $('#divAmount').fadeIn(0);
        Wallet.PaymentObject.resizeToFit();
      });
    },

    //relating to specific divs in the offer/voucher
    showValue: function (amount, isDisplayValue) {
      $('#divAmount').fadeOut(function () {

        //if we have any letters, we're going to display what we've got
        var isString = /[a-zA-Z%]/g.test(amount);
        if (isString || isDisplayValue) {
          $('#divAmount').html(amount);
          Wallet.PaymentObject.resizeToFit();
          $('#divAmount').fadeIn(0);
        }

        //if there are no letters, we've got some numbers to parse for display
        else {
          var convertedInt = parseInt(amount);
          var convertedFloat = parseFloat(amount);
          if (!isNaN(convertedInt) || !isNaN(convertedFloat)) {
            amount = Common.Currencies.get(Wallet.Merchant.currency).symbol + parseFloat(amount).toFixed(2);
            $('#divAmount').html(amount);
          } else {
            $('#divAmount').html(amount);
          }
          Wallet.PaymentObject.resizeToFit();
          $('#divAmount').fadeIn(0);
        }
      });
    },
    showMemberDetails: function (textOverride) {
      var cellPhone = (Wallet.cellPhone ? Wallet.cellPhone /*.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3')*/ + '<br/>' : '');
      var memberID = (Wallet.memberID ? Wallet.memberID : '<br/>');

      if (textOverride) {
        $('#divDetails').html(textOverride);
      }
      else {
        $('#divDetails').html(cellPhone + memberID);
      }
      $('#divDetails').fadeIn();
    },
    showValidPeriod: function (startDate, expDate, overrideContent) {
      //date.setDate(date.getDate()-1);
      if (overrideContent) {
        $('#divExpires').html(overrideContent);
      }
      else {
        $('#divExpires').html('Valid: ' + Common.Date.formatTimeToAMPM(startDate) + ' ' + Common.Date.format(startDate) + ' <br/>Until: ' + Common.Date.formatTimeToAMPM(expDate) + ' ' + Common.Date.format(expDate));
      }
      $('#divExpires').fadeIn();
    },
    showRedeemed: function () {
      $('#divRedeemed').html('Number Redeemed: ' + Wallet.redeemed);
      $('#divRedeemed').fadeIn();
    },
    showUnusable: function (message) {
      $('#paymentObject').css('opacity', 0.25);
      setTimeout(function () {
        $('#paymentUnusable')
          .html(message)
          .fadeIn(2000);
      }, 1500)
    },
    showError: function (error) {
      Wallet.Page.reset();
      Wallet.Page.clearLoadingMessage();

      if (error) {
        $('#divDetails').html('<br/><br/><br/>' + error + '<br/><br/>');
      }
      else {
        $('#divDetails').html('<br/><a href="https://wallet.inc" target="_blank"><img src="/img/logo22-small.png" border="0"/></a><br/><br/><b>Our apologies...</b><span style="font-size:14px;"><br/><br/>We could not locate this item.<br/><br/>It may have have been removed by the merchant. Please check your link and try again.<br/><br/>Thanks,<Br/>The Wallet Team</span>');
      }
      $('#divDetails').fadeIn();
      Wallet.PaymentObject.display();

      $("#walletHeader").fadeOut(0);

    },
    resizeToFit: function (newSize) {
      if (!newSize) newSize = '55px';
      var tempDiv = $('<div></div>').css('font-size', newSize).css('visibility', 'hidden').css('display', 'none');
      tempDiv.html($('#divAmount').html())
      tempDiv.attr('id', "divVirtualTextMeasurement")
      $("#paymentObject").prepend(tempDiv)

      var paddingLeft = parseInt($('#paymentObject').css("padding-left"));
      var paddingRight = parseInt($('#paymentObject').css("padding-right"));
      if (($("#divVirtualTextMeasurement").width() + paddingLeft + paddingRight) > $('#paymentObject').width()) {
        $("#divVirtualTextMeasurement").remove();

        var fontsize = $('#divAmount').css('font-size');
        var newSize = parseFloat(fontsize) - 1;
        $('#divAmount').css('font-size', newSize);
        if (newSize > 16)
          Wallet.PaymentObject.resizeToFit(newSize);
      }
    },

    getExtendedCodeValue: function (barcodeValue) {
      var extendedCodeValue = '';
      var i = 0;
      var character = '';
      while (i <= barcodeValue.length) {
        character = barcodeValue.charAt(i);
        if (!isNaN(character * 1)) {
          extendedCodeValue = extendedCodeValue + character;
        } else {
          if (character == character.toUpperCase()) {
            extendedCodeValue = extendedCodeValue + character;
          }
          if (character == character.toLowerCase()) {
            extendedCodeValue = extendedCodeValue + '-' + character;
          }
        }
        i++;
      }
      return extendedCodeValue;
    },
  },


  //This is the merchant landing page for the Wallet, when switching between companies.

  Login: {
    postLoginRedirectPage: "",
    load: function (pageID) {
      if (pageID) {
        Wallet.Login.postLoginRedirectPage = pageID;
      }
      else {
        Wallet.Login.postLoginRedirectPage = "";
      }

      Wallet.Page.reset();
      $("#divLogin").fadeIn(0);
      Wallet.Authenticate.show();
      Wallet.Page.clearLoadingMessage();
    }
  },

  WalletConfiguration: {
    load: function (data) {
      Wallet.Page.reset();

      $("#divWalletConfiguration").fadeIn(0);
      $("#divCompanyName").text(data.Merchant.companyName);

      if (data.WalletConfiguration.headerCustomIcon) {
        $('#custom-icon')
          .removeClass()
          .addClass('fa-regular fa-' + data.WalletConfiguration.headerCustomIcon)
          .data('custom-icon', data.WalletConfiguration.headerCustomIcon);
      }


      var defaultWelcomeMessage = "Welcome to your personalized wallet for " + data.Merchant.companyName + "!" +
        "<Br/><br/>" +
        "Please use the menu button in the top-left corner to access your personalized perks and rewards." +
        "<Br/><br/>" +
        "Come visit us to redeem these rewards!" +
        "<Br/><br/>" +
        "We are located at:<br/>" +
        data.Merchant.address1 + "<br/>" +
        (data.Merchant.address2 ? data.Merchant.address2 + "<br/>" : "") +
        data.Merchant.city + ", " + data.Merchant.state + " " + data.Merchant.zip;

      if (data.WalletConfiguration.welcomeMessage) {
        data.WalletConfiguration.welcomeMessage = data.WalletConfiguration.welcomeMessage.replace(/>/g, "&gt;").replace(/</g, "&lt;").replace(new RegExp('\r?\n', 'g'), '<br />');
        data.WalletConfiguration.welcomeMessage = Common.String.linkfy(data.WalletConfiguration.welcomeMessage, 30);
      }

      //removes white space
      var whitespace = new RegExp("[ ]+", "g");
      var lb = new RegExp("[(]+", "g");
      var rb = new RegExp("[)]", "g");
      var p = new RegExp("[.]", "g");
      var d = new RegExp("[-]", "g");
      var parsedPhone = data.WalletConfiguration.primaryPhoneNumber ? data.WalletConfiguration.primaryPhoneNumber.replace(whitespace, "").replace(lb, "").replace(rb, "").replace(p, "") : "";
      var parsedWhatsApp = data.WalletConfiguration.primaryWhatsApp ? data.WalletConfiguration.primaryWhatsApp.replace(whitespace, "").replace(lb, "").replace(rb, "").replace(p, "") : "";

      var phoneNumber = parsedPhone ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"tel:" + parsedPhone + "\");'><div class='bg-danger business-card-button'><i class='fa-regular fa-phone-volume' style='color:#fff;'></i></div>Call</a></div>" : "";
      //var smsNumber = parsedPhone ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"tel:" + parsedPhone + "\");'><div class='business-card-button' style='background-color: #2fc452;'><i class='fa-regular fa-messages' style='color:#fff;'></i></div>Text</a></div>" : "";
      //var whatsApp = parsedWhatsApp ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"https://wa.me/" + parsedWhatsApp + "\");'><div class='bg-social-whatsapp business-card-button'><i class='fa-brands fa-whatsapp' style='color:#fff;'></i></div>WhatsApp</a></div>" : "";
      var emailAddress = data.WalletConfiguration.primaryEmailAddress ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"mailto:" + data.WalletConfiguration.primaryEmailAddress + "\");'><div class='bg-info business-card-button'><i class='fa-regular fa-envelope' style='color:#fff;'></i></div>Email</a></div>" : "";

      var instagram = data.WalletConfiguration.socialInstagramURL ? "<div class='social-button-wrapper'><a onclick='Wallet.launchExternalLink(\"" + data.WalletConfiguration.socialInstagramURL + "\");'><div class='bg-social-instagram business-card-button'><i class='fa-brands fa-instagram' style='color:#fff;'></i></div></a></div>" : "";
      var facebook = data.WalletConfiguration.socialFacebookURL ? "<div class='social-button-wrapper'><a onclick='Wallet.launchExternalLink(\"" + data.WalletConfiguration.socialFacebookURL + "\");'><div class='bg-social-facebook business-card-button'><i class='fa-brands fa-facebook' style='color:#fff;'></i></div></a></div>" : "";
      var youTube = data.WalletConfiguration.socialYouTubeURL ? "<div class='social-button-wrapper'><a onclick='Wallet.launchExternalLink(\"" + data.WalletConfiguration.socialYouTubeURL + "\");'><div class='bg-social-youtube business-card-button'><i class='fa-brands fa-youtube' style='color:#fff;'></i></div></a></div>" : "";
      var twitter = data.WalletConfiguration.socialTwitterURL ? "<div class='social-button-wrapper'><a onclick='Wallet.launchExternalLink(\"" + data.WalletConfiguration.socialTwitterURL + "\");'><div class='bg-default business-card-button'><i class='fa-brands fa-x-twitter' style='color:#fff;'></i></div></a></div>" : "";
      var linkedin = data.WalletConfiguration.socialLinkedInURL ? "<div class='social-button-wrapper'><a onclick='Wallet.launchExternalLink(\"" + data.WalletConfiguration.socialLinkedInURL + "\");'><div class='bg-social-linkedin business-card-button'><i class='fa-brands fa-linkedin' style='color:#fff;'></i></div></a></div>" : "";
      var hasAllSocials = (instagram && facebook && youTube && twitter && linkedin ? true : false)
      var connect = instagram || facebook || youTube || twitter || linkedin ? "<div style='display: inline-block;text-align: left;margin-left: 10px;float: left;line-height: 2.5;'>" + (hasAllSocials ? "Connect:" : "Connect with us:") + "</div>" : ""

      var headerImageURL = data.WalletConfiguration.headerImageURL ? '<div class="mobile-wallet-item-img"><img src="' + data.WalletConfiguration.headerImageURL + '" alt="" /></div>' : '';
      var headerImageClass = data.WalletConfiguration.headerImageURL == '' || data.WalletConfiguration.headerImageURL == undefined ? 'mobile-wallet-item-no-header-image' : '';

      var socialBackgroundColor = (data.WalletConfiguration.socialBackgroundColor ? data.WalletConfiguration.socialBackgroundColor : "#fff");
      var socialFontColor = (data.WalletConfiguration.socialFontColor ? data.WalletConfiguration.socialFontColor : "#000");
      var newWelcomeMessage = '<div class="mobile-wallet-item ' + headerImageClass + '">' +
        '<div class="mobile-wallet-item-details">' +
        headerImageURL +

        "<div class='wallet-home-socials' style='display:" + (connect ? 'block' : 'none') + ";background-color:" + socialBackgroundColor + ";color:" + socialFontColor + ";'>" +
        connect +
        "<div style='display: inline-block;text-align: right;padding: 0px 0px 0px 5px;float: right;border-left: 1px dashed #bfbfbf;border-radius:10px'>" + 
        instagram +
        facebook +
        youTube +
        twitter +
        linkedin +
        '</div>' +
        '</div>' +

        '<div class="mobile-wallet-item-body" style="margin-top:0px;">' +
        (data.WalletConfiguration.welcomeMessage || defaultWelcomeMessage) +
        '</div>' +
        "<div style='text-align:center;width:100%;padding: 10px 0px 5px 0px;margin: 0px 0px 0px 0px;display:block;border-top: 1px solid #bfbfbf;border-radius: 20px 20px 0px 0px;display:" + (phoneNumber || emailAddress ? 'block' : 'none') + "'>" +
        phoneNumber +
      //smsNumber +
        emailAddress +
      //whatsApp +
        "</div>" +

        '</div>' +
        '</div>';

      $("#divMerchantLandingPage").html(newWelcomeMessage);

      Wallet.Page.clearLoadingMessage();
      Wallet.Page.resizeCompanyHeaderToFit();

    }
  },


  Representatives: {
    generateVCard: function (id, employeeName) {
      Common.Http.makeGET("/wallet/employee/vcard/" + id, {})
        .done(function (result) {
          Integrations.Google.Analytics.trackEvent('event', 'representative_saved');
          Common.File.downloadVCard(employeeName + ".vcf", result);
        })
        .fail(function (result) {
          Wallet.Modal.alert("Error", "Unable to save employee VCard.")
        })
    },
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.Representatives.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Representatives + Wallet.Configuration.merchantID)
      }
    },
    display: function (data) {

      if (
        data &&
        data.Merchant &&
        data.Merchant.publicRepresentatives &&
        Array.isArray(data.Merchant.publicRepresentatives) &&
        data.Merchant.publicRepresentatives.length > 0
      ) {

        // This is a list of employees with a public profile
        var employees = data.Merchant.publicRepresentatives;
        employees.sort(function (a, b) { return (a.walletSequenceNumber > b.walletSequenceNumber) ? 1 : ((b.walletSequenceNumber > a.walletSequenceNumber) ? -1 : 0); });

        $('#collapsibleEvents').empty();

        if (Wallet.Configuration.isMessages) {
          $("#btnRepresentativesToLiveChatPage").fadeIn(0)
        }


        $.each(employees, function () {

          //employees[0].isOnline = true;
          //employees[0].hasLiveChat = true;
          var scheduleTimeParsed = Common.Date.assembleSchedule(this.schedule).scheduleTime
          var scheduleDaysParsed = Common.Date.assembleSchedule(this.schedule).scheduleDays

          //const profilePictureURL = this.profilePictureURL ? this.profilePictureURL : '/img/user-no-image.jpg';
          var sendChat = false ? '<button class="btn btn-sm btn-default btn-send-chat""><i class="fa-light fa-user-hair-long"></i> Send a Chat</button>' : '';
          var isOnline = this.isOnline ? '<span class="is-online"><i class="fa-solid fa-circle"></i></span>' : '';
          var profilePicture = this.profilePictureURL ? '<div class="item-rep-image">' + isOnline + '<img src="' + this.profilePictureURL + '" alt="" />' + sendChat + '</div>' : '';
          var profilePictureClass = !this.profilePictureURL ? ' item-has-no-profile-pic' : '';
          var scheduleTime = scheduleTimeParsed ? '<div class="mobile-wallet-item-schedule-time"><i class="fa-light fa-clock"></i> ' + scheduleTimeParsed + '</div>' : '';
          var scheduleDays = scheduleDaysParsed ? '<div class="mobile-wallet-item-schedule-days"><i class="fa-light fa-calendar"></i> ' + scheduleDaysParsed + '</div>' : '';
          var name = Common.String.truncateToCharacters(this.firstName + " " + this.lastName, 40);
          var email = '<div class="mobile-wallet-item-email"><i class="fa-light fa-envelope"></i> ' + this.username + '</div>';
          var phoneNumber = this.phoneNumber.length > 0 ? '<div class="mobile-wallet-item-phone-number"><i class="fa-light fa-phone"></i> ' + this.phoneNumber + '</div>' : '';
          var jobTitle = this.jobTitle.length > 0 ? '<div class="mobile-wallet-item-role"><i class="fa-light fa-user-tie-hair"></i> ' + this.jobTitle + '</div>' : '';
          var department = this.department.length > 0 ? '<div class="mobile-wallet-item-dept"><i class="fa-light fa-users-between-lines"></i> ' + this.department + '</div>' : ""
          var saveContact = '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.Representatives.generateVCard(\'' + this.id + '\', \'' + this.firstName + " " + this.lastName + '\'); "><i class="fa-light fa-user-plus"></i> Save Contact</button>';


          var item = '<div class="mobile-wallet-item' + profilePictureClass + '">' +
            '<div class="mobile-wallet-item-details item-representative">' +
            profilePicture +
            '<div class="item-rep-schedule">' +
            '<h5 class="item-rep-title">' + name + '</h5>' +
            scheduleTime +
            scheduleDays +
            email +
            phoneNumber +
            jobTitle +
            department +
            saveContact +
            '</div>' +
            '</div>' +
            '</div>';

          $('#collapsibleEvents').append(item);
        });

        $('.btn-send-chat').on('click', function () { $('#btnChat').click() })

        $('div[data-role=collapsible]').collapsible();
        $('#collapsibleEvents').fadeIn();
        Wallet.Page.pushState(data);
      }
      else {
        $("#divRepresentativesNone").html("Please check back soon for the contact details of our teams, people, or employees!")
      }

      $("#divRepresentatives").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
    }
  },



  MapDirections: {
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.MapDirections.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.MapDirections + Wallet.Configuration.merchantID)
      }
    },
    display: function (data) {

      if (data) {
        Wallet.loadLibrary("googleMaps", "https://maps.googleapis.com/maps/api/js?location=-33.8670522,151.1957362&key=AIzaSyCEEEagUbjouwemelE2QlvQIAnz6GZE5F4", function () {

          var addressString = data.Merchant.address1 + ' ' + data.Merchant.address2 + ' ' + data.Merchant.city + ', ' + data.Merchant.state + ' ' + data.Merchant.zip + ' ' + data.Merchant.country;
          var geocoder = new google.maps.Geocoder();
          geocoder.geocode({ 'address': addressString }, function (results, status) {

            if (status == 'OK') {

              mapView = new google.maps.Map(document.getElementById("divMapDirectionsMap"), {
                center: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng()
                },
                zoom: 10,
              });

              mapView.setCenter(results[0].geometry.location);
              var marker = new google.maps.Marker({
                map: mapView,
                position: results[0].geometry.location
              });


              var contentString = '<div id="content"><div id="bodyContent">' +
                data.Merchant.companyName + '<br/>' +
                data.Merchant.address1 + '<br/>' +
                (data.Merchant.address2 ? data.Merchant.address2 + '<br/>' : "") +
                data.Merchant.city +
                (data.Merchant.state ? ',' + data.Merchant.state + '<br/>' : "") +
                (data.Merchant.zip ? ' ' + data.Merchant.zip + '<br/>' : "") +
                (data.Merchant.country ? data.Merchant.country + '<br/>' : "") +

                '<br>Latitude: <b>' + results[0].geometry.location.lat() + '</b> <br> Longtitude: <b>' + results[0].geometry.location.lng() + '</b> </div></div>';
              infowindow = new google.maps.InfoWindow({
                content: contentString,
              });

              marker = new google.maps.Marker({
                position: {
                  lat: results[0].geometry.location.lat(),
                  lng: results[0].geometry.location.lng()
                },
                map: mapView,
              });

              marker.addListener("click", function () {
                infowindow.open(mapView, marker);
              });

              $("#btnMapDirectionsDetails").on("click", function () {
                window.open("https://www.google.com/maps?q=" + results[0].geometry.location.lat() + "," + results[0].geometry.location.lng())
              });
              $("#divMapDirectionsMap").fadeIn();
              $("#btnMapDirectionsDetails").fadeIn();

            } else {
              alert('Geocode was not successful for the following reason: ' + status);
            }
          });

          $("#divMapDirections").fadeIn(0);
          Wallet.Page.pushState(data);
        })
      }



      Wallet.Page.clearLoadingMessage();
    }
  },

  AdvertisementCredit: {
    load: function (data) {
      Wallet.Page.reset();

      if (!data) {
        var adCreditScanID = Wallet.AdvertisementCredit.openStorage();
        if (adCreditScanID) {
          return Wallet.loadWallet(adCreditScanID);
        }
      }
      Wallet.AdvertisementCredit.display(data);
    },

    display: function (data) {
      if (!data) {
        $("#divAdCredits").fadeIn(0);
        $('#divNoneToDisplay').html("You haven't scanned a QR code on any of our advertisements yet. <br/><br/>Once you have, you can access those perks & discounts here.");
        $('#divNoneToDisplay').fadeIn(0);
        Wallet.Page.clearLoadingMessage();
        Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.AdvertisementCredit), 'page_location': '(none for user)', 'send_page_view': true });
        return false;
      }
      if (Wallet.isScaffoldingVisible) $("#divAdCredits").fadeIn(0);

      Object.assign(Wallet.PaymentObject.Design, data.PaymentDesign);
      Wallet.PaymentObject.Design.name = data.PaymentDesign.displayName;
      Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon, true);
      Wallet.PaymentObject.displayDesign();

      Wallet.PaymentObject.showMemberDetails();
      var barcodeValue = data.ID;
      if (data.IsInfogenesisIntegrated) {
        barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(barcodeValue);
      }

      Wallet.Page.clearLoadingMessage();
      Wallet.PaymentObject.display();


      Wallet.PaymentObject.startingAmount = data.DiscountValue;
      Wallet.PaymentObject.currentAmount = data.DiscountValue;
      //Wallet.PaymentObject.valueToDisplay = data.DiscountValue;
      Wallet.PaymentObject.valueType = data.ValueType;

      var amount = Wallet.PaymentObject.getDisplayAmount();
      Wallet.PaymentObject.showValue(amount);

      if (data.IsAdvertisementCreditExhausted) {
        return Wallet.PaymentObject.showUnusable('All Discounts<Br/>Claimed');
      }

      if (!data.IsActive || !data.AdvertisementCredit.isActive) {
        return Wallet.PaymentObject.showUnusable('VOID')
      }

      //has it been redeemed?
      if (data.IsRedeemed) {
        var redeemedAmount = (data.RedeemedAmount ? Common.Integer.formatToCurrency(data.RedeemedAmount, Common.Currencies.get(Wallet.Merchant.currency).symbol) + '<br/>' : '');
        return Wallet.PaymentObject.showUnusable(redeemedAmount + 'Redeemed On<br/>' + Common.Date.format(data.DateTimeRedeemed));
      }

      Wallet.Barcodes.show(barcodeValue);

      Wallet.AdvertisementCredit.updateStorage(data);
      Wallet.Page.pushState(data);

      Integrations.Apple.Wallet.addLink({
        afterElement: "#paymentObject"
      });

    },
    openStorage: function () {
      var adCredits = [];
      var key = "AdCredits-" + Wallet.Configuration.merchantID;
      if (localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)).length > 0) {
        adCredits = JSON.parse(localStorage.getItem(key))
        if (adCredits.length > 0) {
          return adCredits.pop();
        }
      }
      return false;
    },
    updateStorage: function (data) {
      var adCredits = [];
      var key = "AdCredits-" + Wallet.Configuration.merchantID;
      if (localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)).length > 0) {
        adCredits = JSON.parse(localStorage.getItem(key))
        if (adCredits.indexOf(data.ID) == -1) {
          adCredits.push(data.ID)
          localStorage.setItem(key, JSON.stringify(adCredits));
        }
      }
      else {
        adCredits.push(data.ID)
        localStorage.setItem(key, JSON.stringify(adCredits));
      }
    },

  },


  StaticVoucher: {
    isListOfCouponsLoaded: false,
    isPageLoadingOtherCoupons: false,
    listOfAllForUser: [],
    startDate: new Date(),
    expirationDate: new Date(),
    load: function (data) {
      Wallet.Page.reset();

      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (phoneVerificationToken && !data) {
        Common.Http.makePOST("/wallet/paymentObject/token", {
          phoneVerificationToken: phoneVerificationToken,
          merchantID: Wallet.Configuration.merchantID,
          pageType: Common.Prefixes.StaticVoucher,
          referrer: document.referrer
        })
          .done(function (results) {
            data = (results && results.ID ? results : data);
            Wallet.StaticVoucher.display(data);
          })
          .fail(function (err) {
            Wallet.StaticVoucher.display();
            Wallet.Page.clearLoadingMessage();
          });
      } else {
        Wallet.StaticVoucher.display(data);
      }

    },
    display: function (data) {

      if (!data) {
        $("#divStaticVouchers").fadeIn(0);

        if (!Common.Cookie.get("CustomerMobileNumber")) {
          $('#divNoneToDisplay').text("Please verify your phone number to access your offers.");
          $('#divNoneToDisplay').fadeIn(0);
          Wallet.Authenticate.show();
        }
        else {
          $('#divNoneToDisplay').text("We don't have any offers associated with you yet. Please check back soon!");
          $('#divNoneToDisplay').fadeIn(0);
          Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.StaticVoucher), 'page_location': '(none for user)', 'send_page_view': true });
        }

        Wallet.Page.clearLoadingMessage();
        return false;
      }
      Wallet.Page.pushState(data);

      if (!data && !data.CampaignID) {
        return Wallet.PaymentObject.showError();
      }

      if (Wallet.isScaffoldingVisible) $("#divStaticVouchers").fadeIn(0);


      //set page level values for customer
      Wallet.customerCell = data.CellPhoneNumber;
      Wallet.memberID = (data.MemberID ? data.MemberID : data.CustomerID);


      //set PaymentObject properties
      Wallet.PaymentObject.startingAmount = data.OfferAmountCents;
      Wallet.PaymentObject.currentAmount = Wallet.PaymentObject.startingAmount;
      Wallet.PaymentObject.valueToDisplay = Wallet.PaymentObject.currentAmount;
      Wallet.PaymentObject.valueType = data.ValueType;
      //Wallet.PaymentObject.faceValue = data.faceValue;

      Object.assign(Wallet.PaymentObject.Design, data.PaymentDesign);
      Wallet.PaymentObject.Design.name = data.PaymentDesign.displayName;
      Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon, true)
      Wallet.PaymentObject.displayDesign();

      Wallet.PaymentObject.showMemberDetails();

      Wallet.StaticVoucher.startDate = new Date(data.DateTimeStartCampaign);
      Wallet.StaticVoucher.expirationDate = new Date(data.DateTimeEndCampaign);
      Wallet.PaymentObject.showValidPeriod(Wallet.StaticVoucher.startDate, Wallet.StaticVoucher.expirationDate);
      Wallet.PaymentObject.display();

      if (data.DisplayValue) {
        Wallet.PaymentObject.showValue(data.DisplayValue, true);
      }
      else {
        var amount = Wallet.PaymentObject.getDisplayAmount();
        Wallet.PaymentObject.showValue(amount);
      }

      if (data.PosValidMachines && data.PosValidMachines.length > 0) {
        $("#divSupplementalInfo").text("Valid only at: " + data.PosValidMachines.join(","))
        $("#divSupplementalInfo").fadeIn(0)
      }

      //grab all StaticVouchers for this user.
      if (Wallet.isScaffoldingVisible) {
        Wallet.StaticVoucher.getAllForUser(data.ID, function () {
          $('#controlGroupButtons').fadeIn(0);
          Wallet.StaticVoucher.Active.load();
          Wallet.Page.clearLoadingMessage();
        });
      }
      else {
        Wallet.Page.clearLoadingMessage();
      }


      Wallet.StaticVoucher.lastVoucherViewed = data;

      if (!data.IsActive) {
        return Wallet.PaymentObject.showUnusable('VOID');
      }

      //has it been redeemed?
      if (data.IsRedeemed) {
        var redeemedAmount = (data.RedeemedAmount ? Common.Integer.formatToCurrency(data.RedeemedAmount, Common.Currencies.get(Wallet.Merchant.currency).symbol) : Common.Integer.formatToCurrency(0, Common.Currencies.get(Wallet.Merchant.currency).symbol)) + '<br/>';
        return Wallet.PaymentObject.showUnusable(redeemedAmount + 'Redeemed On<br/>' + Common.Date.format(data.DateTimeRedeemed));
      }

      //has it expired?
      if ((new Date()).getTime() > Wallet.StaticVoucher.expirationDate.getTime()) {
        return Wallet.PaymentObject.showUnusable('Expired On<br/>' + Common.Date.format(Wallet.StaticVoucher.expirationDate));
      }


      //good to go, display this baby
      $('#paymentUnusable').fadeOut(0);
      $('#paymentObject').css('opacity', 1);


      var barcodeValue = data.ID;
      if (data.IsInfogenesisIntegrated) {
        barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(barcodeValue);
      }
      Wallet.Barcodes.show(barcodeValue);

      Integrations.Apple.Wallet.addLink({
        afterElement: "#paymentObject"
      });

      Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.StaticVoucher), 'page_location': data.ID, 'send_page_view': true });
    },


    getAllForUser: function (voucherID, callback) {

      console.time('fetch All StaticVouchers');

      //V2_GetStaticVouchersForCustomer
      Common.Http.makeGET("/wallet/staticVoucher/all", { voucherID: voucherID })
        .done(function (results) {
          console.timeEnd('fetch All StaticVouchers');
          Wallet.StaticVoucher.listOfAllForUser = results;
          if (Common.isFunction(callback)) {
            callback();
          }
        })
        .fail(function (err) {
          console.error(JSON.stringify(err));
        });

    },


    Active: {
      load: function () {
        $('#divNoneToDisplay').text('');
        $('#btnActive').addClass('ui-btn-active ui-state-persist');
        $('#btnExpired').removeClass('ui-btn-active ui-state-persist');
        $('#btnRedeemed').removeClass('ui-btn-active ui-state-persist');
        $('#collapsibleCoupons').empty();
        Wallet.StaticVoucher.getActiveForUser();
      }
    },
    Expired: {
      load: function () {
        $('#divNoneToDisplay').text('');
        $('#btnActive').removeClass('ui-btn-active ui-state-persist');
        $('#btnExpired').addClass('ui-btn-active ui-state-persist');
        $('#btnRedeemed').removeClass('ui-btn-active ui-state-persist');
        $('#collapsibleCoupons').empty();
        Wallet.StaticVoucher.getExpiredForUser();
      }
    },


    getActiveForUser: function () {
      var activeVouchers = [];
      $.each(Wallet.StaticVoucher.listOfAllForUser, function () {
        if (!this.IsRedeemed && new Date(this.ExpirationDate) > new Date()) {
          activeVouchers.push(this);
        }
      });
      Wallet.StaticVoucher.showListOfActive(activeVouchers);
      Wallet.isListOfWalletsLoaded = true;
    },
    getExpiredForUser: function () {
      var expiredVouchers = [];
      $.each(Wallet.StaticVoucher.listOfAllForUser, function () {
        if (!this.IsRedeemed && new Date(this.ExpirationDate) < new Date()) {
          expiredVouchers.push(this);
        }
      });
      Wallet.StaticVoucher.showListOfExpired(expiredVouchers);
      Wallet.isListOfStaticVouchersLoaded = true;
    },


    showListOfActive: function (listOfStaticVouchersForUser) {
      console.time('Active StaticVouchers rendering');
      listOfStaticVouchersForUser.sort( //sort by Expiration Date, ascending (soonest date first)
        function (a, b) { return (a.ExpirationDate > b.ExpirationDate) ? 1 : ((b.ExpirationDate > a.ExpirationDate) ? -1 : 0); }
      );

      var summary = [];
      $.each(listOfStaticVouchersForUser, function () {
        var isFound = false;
        for (var i = 0; i < summary.length; i++) {
          if (summary[i]) {
            if (summary[i].typeId === this.PaymentDesign.id) {
              summary[i].sumOffers = summary[i].sumOffers + 1;
              summary[i].sumValue = summary[i].sumValue + this.OfferAmountCents;
              summary[i].body = summary[i].body + Wallet.StaticVoucher.createLinkToActive(this, this.ValueType);
              isFound = true;
            }
          }
        }

        if (!isFound) {
          var record = {};
          record.typeId = this.PaymentDesign.id;
          record.typeName = (this.PaymentDesign ? this.PaymentDesign.displayName : '');
          record.icon = (this.PaymentDesign ? Common.Icons.generate(this.PaymentDesign.icon) : '');
          record.sumOffers = 1;
          record.sumValue = this.OfferAmountCents;
          record.soonestExpiration = this.ExpirationDate;
          record.body = Wallet.StaticVoucher.createLinkToActive(this, this.ValueType);
          summary.push(record);
        }
      });

      var oldGroup = null;
      var count = 0;
      $.each(summary, function () {
        var newGroup = '';
        oldGroup = newGroup;

        newGroup = newGroup +
          '<div data-role="collapsible" data-theme="c" data-content-theme="c" data-collapsed-icon="arrow-r" data-expanded-icon="arrow-d" data-iconpos="right" class="voucherCollapsible">' +
          '<h3>' +
          '<div>' +
          '<span>' + this.icon + ' ' + this.typeName + '</span><br/>' +
          '<span style="font-size:small;font-weight: 100;">' + ((this.sumOffers > 1) ? (this.sumOffers + ' Offers') : (this.sumOffers + ' Offer')) + '</span>' +
          '</div>' +
          '</h3>' +
          '<div class="greyCollapsibleInset">' +
          this.body +
          '</div>' +
          '</div>';
        $('#collapsibleCoupons').append(newGroup);
        count++;
      });

      $('#collapsibleCoupons').prepend('<br/><br/><span style=\'font-size:25px;float: left;margin-top:-12px;\'><i class="fa-regular fa-tags"></i> Active:</span><br/><br/>');
      if (count === 0) {
        $('#divNoneToDisplay').text('You have no offers currently available.').addClass("align-center").fadeIn(0);
      }
      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleCoupons').fadeIn(0);
      console.timeEnd('Active StaticVouchers rendering');
    },




    showListOfExpired: function (listOfStaticVouchersForUser) {
      console.time('Expired StaticVouchers rendering');
      listOfStaticVouchersForUser.sort( //sort by Expiration Date, ascending (soonest date first)
        function (a, b) { return (a.ExpirationDate > b.ExpirationDate) ? 1 : ((b.ExpirationDate > a.ExpirationDate) ? -1 : 0); }
      );

      var summary = [];
      $.each(listOfStaticVouchersForUser, function () {
        var isFound = false;
        for (var i = 0; i < summary.length; i++) {
          if (summary[i]) {
            if (summary[i].typeId == this.PaymentDesign.id) {
              summary[i].sumOffers = summary[i].sumOffers + 1;
              summary[i].sumValue = summary[i].sumValue + this.OfferAmountCents;
              summary[i].body = summary[i].body + Wallet.StaticVoucher.createLinkToExpired(this, this.ValueType);
              isFound = true;
            }
          }
        }

        if (!isFound) {
          var record = {};
          record.typeId = this.PaymentDesign.id;
          record.typeName = (this.PaymentDesign.displayName || '');
          record.icon = (this.PaymentDesign ? Common.Icons.generate(this.PaymentDesign.icon) : '');
          record.sumOffers = 1;
          record.sumValue = this.OfferAmountCents;
          record.soonestExpiration = this.ExpirationDate;
          record.body = Wallet.StaticVoucher.createLinkToExpired(this, this.ValueType);
          summary.push(record);
        }
      });

      var count = 0;
      var newGroup = '<br/><br/><span style=\'font-size:25px;float: left;margin-top:-12px;\'><i class="fa-regular fa-clock"></i> Expired:</span><br/><br/>';
      $('#collapsibleCoupons').append(newGroup);
      $.each(summary, function () {
        newGroup =
          '<div data-role="collapsible" data-theme="c" data-content-theme="c" data-collapsed-icon="arrow-r" data-expanded-icon="arrow-d" data-iconpos="right" class="voucherCollapsible">' +
          '<h3>' +
          '<div>' +
          '<span>' + this.icon + ' ' + this.typeName + '</span><br/>' +
          '<span style="font-size:small;font-weight: 100;">' + ((this.sumOffers > 1) ? (this.sumOffers + ' Offers') : (this.sumOffers + ' Offer')) + '</span>' +
          '</div>' +
          '</h3>' +
          '<div class="greyCollapsibleInset">' +
          this.body +
          '</div>' +
          '</div>';
        $('#collapsibleCoupons').append(newGroup);
        count++;
      });
      if (count === 0) {
        $('#divNoneToDisplay').text('No expired offers to display.').addClass("align-center").fadeIn(0);
      }
      $('#collapsibleCoupons').fadeIn(0);
      $('div[data-role=collapsible]').collapsible();
      console.timeEnd('Expired StaticVouchers rendering');
    },
    createLinkToActive: function (voucher, valueType) {
      var numDays = Common.Date.getDifferenceBetween(new Date(), voucher.ExpirationDate);
      var shortDate = Common.convertNumberOfDaysToText(numDays);
      var discount = Common.Integer.getFaceValue(voucher.OfferAmountCents, valueType, Common.Currencies.get(Wallet.Merchant.currency).symbol);
      var icon = (voucher.PaymentDesign ? Common.Icons.generate(voucher.PaymentDesign.icon) : '');
      var link = ' <a href="#" onclick="Wallet.loadWallet(\'' + voucher.id + '\');">' + (voucher.DisplayValue ? voucher.DisplayValue : discount) + '</a> ';
      var expiration = '<span class=\'collapsibleInsetExpiration\'><b>Expires:</b> ' + Common.Date.format(voucher.ExpirationDate) + '</span><br/>';
      return icon + link + expiration;
    },
    createLinkToExpired: function (voucher, valueType) {
      var discount = Common.Integer.getFaceValue(voucher.OfferAmountCents, valueType, Common.Currencies.get(Wallet.Merchant.currency).symbol);
      var icon = (voucher.PaymentDesign ? Common.Icons.generate(voucher.PaymentDesign.icon) : '');
      var link = ' <a href="#" onclick="Wallet.loadWallet(\'' + voucher.id + '\');">' + (voucher.DisplayValue ? voucher.DisplayValue : discount) + '</a> ';
      var expiration = '<span class=\'collapsibleInsetExpiration\'><b>Expired:</b> ' + Common.Date.format(voucher.ExpirationDate) + '</span><br/>';
      return icon + link + expiration;
    },
  },

  DynamicVoucher: {
    redeemed: 0,
    pollingSpeed: 20000,
    pollingTimeLeft: 0,
    totalNumberRedeemed: 0,

    temporalDecreaseAmount: 0,
    temporalDecreaseFrequency: 0,
    temporalDecreaseFrequencyType: null,

    clearPollingMechanism: function () {
      clearInterval(Wallet.DynamicVoucher.countdownInterval);
      Wallet.DynamicVoucher.countdownInterval = null;
    },


    removeOldFormatFromStorage: function () {
      //get all old keys
      var keys = Object.keys(localStorage).filter(function (key) {
        return /^DynamicVouchers-DV/.test(key);
      });
      keys.map(function (key) {
        localStorage.removeItem(key)
      });
    },

    load: function (data, isRefresh) {
      Wallet.Page.reset();

      Wallet.DynamicVoucher.removeOldFormatFromStorage();

      if (!data) {
        //if we have an item in storage, use it.
        var storage = Wallet.DynamicVoucher.openStorage();
        if (storage) {
          return Wallet.loadWallet(storage);
        }

        $("#divDynamicVouchers").fadeIn(0);
        $('#divNoneToDisplay').text("Please check back soon for great time-sensitive offers!");
        $('#divNoneToDisplay').fadeIn(0);
        Wallet.Page.clearLoadingMessage();
        return false;
      }

      if (Wallet.isScaffoldingVisible) $("#divDynamicVouchers").fadeIn(0);

      var dateTimeStart = data.startDate;
      var dateTimeEnd = data.expirationDate;
      var currentValue = data.currentValue_decimal;

      Wallet.Page.clearLoadingMessage();

      Wallet.PaymentObject.currentAmount = currentValue;
      Wallet.PaymentObject.showValue(Wallet.PaymentObject.currentAmount);


      //Refresh the Redemption number
      //todo: implement
      //Wallet.DynamicVoucher.changeRedeemed(redeemedCount);


      //SET UI STUFF
      Wallet.PaymentObject.display();


      Wallet.PaymentObject.Design = data.PaymentDesign;
      Wallet.PaymentObject.Design.displayName = data.PaymentDesign.displayName;
      Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon);
      Wallet.PaymentObject.displayDesign();
      Wallet.PaymentObject.showValidPeriod(dateTimeStart, dateTimeEnd);
      Wallet.PaymentObject.resizeToFit();


      //If we are polling/refreshing, let's not fill their browser history with multiple page visits for each refresh
      if (!isRefresh) Wallet.Page.pushState(data);



      var dynamicVoucherIDwithMetaValue = Wallet.DynamicVoucher.saveToStorage(data);
      var metaValue = dynamicVoucherIDwithMetaValue.split("--")[1];


      if (!data.IsActive) {
        return Wallet.PaymentObject.showUnusable('VOID');
      }

      //the gamut of zero-value scenarios
      if (data.CalculationSummary.redeemedKeys.indexOf(metaValue) > -1) {
        return Wallet.PaymentObject.showUnusable('Redeemed');
      }
      if (data.CalculationSummary && data.CalculationSummary.status === "wait") {
        return Wallet.PaymentObject.showUnusable('Begins On<br/>' + Common.Date.format(data.startDate));
      }
      if (data.CalculationSummary && data.CalculationSummary.status === "completed") {
        return Wallet.PaymentObject.showUnusable('Expired On<br/>' + Common.Date.format(data.expirationDate));
      }
      if (data.CalculationSummary && data.CalculationSummary.timeValueZero) {
        return Wallet.PaymentObject.showUnusable('No Value <Br/>Remaining');
      }
      if (data.CalculationSummary && data.CalculationSummary.currentValue === 0 && data.CalculationSummary.maximumBudgetExhausted) {
        return Wallet.PaymentObject.showUnusable('All Vouchers <Br/>Claimed');
      }
      if (data.CalculationSummary && data.CalculationSummary.calcError) {
        return Wallet.PaymentObject.showUnusable('Unexpected<br/>Error')
      }



      var barcodeValue = Wallet.itemID;
      if (data.IsInfogenesisIntegrated) {
        var splitDynamicVoucherKey = dynamicVoucherIDwithMetaValue.split("--");
        barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(splitDynamicVoucherKey[0]) + "--" + Wallet.PaymentObject.getExtendedCodeValue(splitDynamicVoucherKey[1]);
      }
      else {
        barcodeValue = dynamicVoucherIDwithMetaValue;
      }

      Wallet.Barcodes.show(barcodeValue);

      Wallet.DynamicVoucher.totalNumberRedeemed = data.CalculationSummary.totalNumberRedeemed;
      Wallet.DynamicVoucher.temporalDecreaseAmount = data.temporalDecreaseAmount;
      Wallet.DynamicVoucher.temporalDecreaseFrequency = data.temporalDecreaseFrequency;
      Wallet.DynamicVoucher.temporalDecreaseFrequencyType = data.temporalDecreaseFrequencyType;
      Wallet.DynamicVoucher.setSupplementalInfo("Total number redeemed so far: <b><u>" + Wallet.DynamicVoucher.totalNumberRedeemed + "</u></b>.<br/>Refreshing in " + parseInt(Wallet.DynamicVoucher.pollingTimeLeft / 1000) + " seconds.");
      $("#divSupplementalInfo").fadeIn(0)

      Wallet.DynamicVoucher.pollingTimeLeft = Wallet.DynamicVoucher.pollingSpeed;

      Wallet.DynamicVoucher.countdownInterval = setInterval(function () {
        Wallet.DynamicVoucher.pollingTimeLeft -= 1000;
        Wallet.DynamicVoucher.setSupplementalInfo("Total number redeemed so far: <b><u>" + Wallet.DynamicVoucher.totalNumberRedeemed + "</u></b>.<br/>Refreshing in " + parseInt(Wallet.DynamicVoucher.pollingTimeLeft / 1000) + " seconds.");
        if (Wallet.DynamicVoucher.pollingTimeLeft <= 0) {
          clearInterval(Wallet.DynamicVoucher.countdownInterval);
          Wallet.DynamicVoucher.countdownInterval = null;
          return Wallet.loadWallet(data.ID, undefined, true);
        }
      }, 1000);

    },

    openStorage: function () {
      var adCredits = [];
      var key = "DynamicVouchers-" + Wallet.Configuration.merchantID;
      if (localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)).length > 0) {
        adCredits = JSON.parse(localStorage.getItem(key))
        if (adCredits.length > 0) {
          var dynamicVoucher = adCredits.pop();
          return dynamicVoucher.split("--")[0]
        }
      }
      return false;
    },
    saveToStorage: function (data) {
      var dynamicVouchers = [];
      var merchantKey = "DynamicVouchers-" + Wallet.Configuration.merchantID;
      var newDynamicVoucherKey = data.ID + "--" + Common.String.generateRandom(4, undefined, true);

      //merchant key exists, containing some dynamic voucher IDs
      if (localStorage.getItem(merchantKey) && JSON.parse(localStorage.getItem(merchantKey)).length > 0) {
        dynamicVouchers = JSON.parse(localStorage.getItem(merchantKey))
        var isFound = false;

        //is the one we're currently looking at already in storage?
        for (var i = 0; i < dynamicVouchers.length; i++) {
          if (dynamicVouchers[i].indexOf(data.ID) > -1) {

            //it is! return it and stop execution.
            return dynamicVouchers[i];
          }
        }


        //it was not found in storage. save it, with the new metaValue
        dynamicVouchers.push(newDynamicVoucherKey)
        localStorage.setItem(merchantKey, JSON.stringify(dynamicVouchers));
        return newDynamicVoucherKey;
      }

      //merchant key does not yet exist
      else {
        dynamicVouchers.push(newDynamicVoucherKey)
        localStorage.setItem(merchantKey, JSON.stringify(dynamicVouchers));
        return newDynamicVoucherKey;
      }


    },
    setSupplementalInfo: function (supplementalInfo) {
      var countdownMetric = "This offer loses " + Common.Integer.formatToCurrency(Wallet.DynamicVoucher.temporalDecreaseAmount, Common.Currencies.get(Wallet.Merchant.currency).symbol) + " every " + Wallet.DynamicVoucher.temporalDecreaseFrequency + " " + Wallet.DynamicVoucher.temporalDecreaseFrequencyType + ".<br/>"
      $("#divSupplementalInfo").html(countdownMetric + supplementalInfo);
    },
    changeRedeemed: function (num) {
      Wallet.DynamicVoucher.redeemed = num;
      Wallet.PaymentObject.showRedeemed();
    }
  },

  MembershipTierRedeemable: {
    load: function (data) {
      Wallet.Page.reset();

      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (phoneVerificationToken && !data) {
        Common.Http.makePOST("/wallet/paymentObject/token", {
          phoneVerificationToken: phoneVerificationToken,
          merchantID: Wallet.Configuration.merchantID,
          pageType: Common.Prefixes.MembershipTierRedeemable,
          referrer: document.referrer
        })
          .done(function (results) {
            data = (results && results.ID ? results : data);
            Wallet.MembershipTierRedeemable.display(data);
            Wallet.Page.clearLoadingMessage();
          })
          .fail(function (err) {
            Wallet.MembershipTierRedeemable.display();
            Wallet.Page.clearLoadingMessage();
          });
      } else {
        Wallet.MembershipTierRedeemable.display(data);
        Wallet.Page.clearLoadingMessage();
      }
    },
    display: function (data) {
      if (!data) {
        $("#divMembershipTier").fadeIn(0);

        if (!Common.Cookie.get("CustomerMobileNumber")) {
          Wallet.Authenticate.show();
          $('#divNoneToDisplay').text("Please verify your phone number to access your membership rewards.");
          $('#divNoneToDisplay').fadeIn(0);
        }
        else {
          $('#divNoneToDisplay').text("You're not associated with a membership club yet. To request membership rewards, please contact us.");
          $('#divNoneToDisplay').fadeIn(0);
          Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.MembershipTierRedeemable), 'page_location': '(none for user)', 'send_page_view': true });
        }

        Wallet.Page.clearLoadingMessage();
        return false;
      }
      if (Wallet.isScaffoldingVisible) $("#divMembershipTier").fadeIn(0);


      if (data.PaymentDesign) {
        Object.assign(Wallet.PaymentObject.Design, data.PaymentDesign);
        Wallet.PaymentObject.Design.name = data.PaymentDesign.displayName;
        Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon, true);
        Wallet.PaymentObject.displayDesign();
      }
      else {
        Wallet.PaymentObject.Design = {
          id: "default",
          borderColor: "#808080",
          borderStyleType: "Dashed",
          borderSize: "4px",
          fontColor: "333333",
          fontType: "Tahoma",
          //backgroundImageURL: "/img/background-min.jpg",
        };
        Wallet.PaymentObject.Design.icon = "<i class='fa-regular fa-2x fa-medal'></i>";
        Wallet.PaymentObject.displayDesign();
      }


      Wallet.PaymentObject.showMemberDetails();




      Wallet.Page.clearLoadingMessage();
      Wallet.PaymentObject.display();
      Wallet.PaymentObject.showValue(data.MembershipTier.tierName);
      Wallet.PaymentObject.resizeToFit();


      $('#divDetails').html(data.MembershipTier.tierDiscount + "% Discount");
      $('#divDetails').fadeIn();

      if (!data.MembershipTier.isActive) {
        return Wallet.PaymentObject.showUnusable('VOID');
      }

      var barcodeValue = data.ID;
      if (data.IsInfogenesisIntegrated) {
        barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(barcodeValue);
      }
      Wallet.Barcodes.show(barcodeValue);


      Wallet.Page.pushState(data);
      Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.MembershipTierRedeemable), 'page_location': data.ID, 'send_page_view': true });
    }
  },

  MembershipPoints: {
    load: function (data) {
      Wallet.Page.reset();

      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (phoneVerificationToken && !data) {
        Common.Http.makePOST("/wallet/paymentObject/token", {
          phoneVerificationToken: phoneVerificationToken,
          merchantID: Wallet.Configuration.merchantID,
          pageType: Common.Prefixes.MembershipPoints,
          referrer: document.referrer
        })
          .done(function (results) {
            data = (results && results.ID ? results : data);
            Wallet.MembershipPoints.display(data);
          })
          .fail(function (err) {
            Wallet.MembershipPoints.display();
            Wallet.Page.clearLoadingMessage();
          });
      } else {
        Wallet.MembershipPoints.display(data);
      }
    },
    display: function (data) {
      if (!data) {
        $("#divMembershipPoints").fadeIn(0);

        if (!Common.Cookie.get("CustomerMobileNumber")) {
          Wallet.Authenticate.show();
          $('#divNoneToDisplay').text("Please verify your phone number to access your membership points.");
          $('#divNoneToDisplay').fadeIn(0);
        }
        else {
          $('#divNoneToDisplay').text("No membership points are associated with your profile yet.");
          $('#divNoneToDisplay').fadeIn(0);
          Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.MembershipPoints), 'page_location': '(none for user)', 'send_page_view': true });
        }

        Wallet.Page.clearLoadingMessage();
        return false;
      }

      if (Wallet.isScaffoldingVisible) $("#divMembershipPoints").fadeIn(0);

      if (data.PaymentDesign) {
        Object.assign(Wallet.PaymentObject.Design, data.PaymentDesign);
        Wallet.PaymentObject.Design.name = data.PaymentDesign.displayName;
        Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon, true);
        Wallet.PaymentObject.displayDesign();
      }
      else {
        Wallet.PaymentObject.Design = {
          id: "default",
          borderColor: "#808080",
          borderStyleType: "Dashed",
          borderSize: "4px",
          fontColor: "333333",
          fontType: "Tahoma",
          //backgroundImageURL: "/img/background-min.jpg",
          icon: "<i class='fa-regular fa-2x fa-trophy-alt'></i>"
        };
        Wallet.PaymentObject.displayDesign();
      }

      Wallet.PaymentObject.showMemberDetails();

      var barcodeValue = data.MembershipPoints.id;
      if (data.IsInfogenesisIntegrated) {
        barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(barcodeValue);
      }

      Wallet.Page.clearLoadingMessage();
      Wallet.PaymentObject.display();
      if (data.MembershipPoints.isActive) {
        Wallet.PaymentObject.showValue(Common.numberWithCommas(data.MembershipPoints.pointsAccrued) + " Points");
      } else {
        // Display 0 points
        Wallet.PaymentObject.showValue(Common.numberWithCommas(data.MembershipPoints.pointsAccrued) + " Points");
        return Wallet.PaymentObject.showUnusable('SUSPENDED');
      }

      Wallet.Barcodes.show(barcodeValue);

      Integrations.Apple.Wallet.addLink({
        afterElement: "#paymentObject"
      });

      Wallet.Page.pushState(data);
      Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.MembershipPoints), 'page_location': data.MembershipPoints.id, 'send_page_view': true });
    }
  },

  MembershipLevel: {
    load: function (data) {
      Wallet.Page.reset();

      if (!data) {
        $("#divMembershipLevel").fadeIn(0);

        if (!Common.Cookie.get("CustomerMobileNumber")) {
          Wallet.Authenticate.show();
          $('#divNoneToDisplay').text("Please verify your phone number to access your membership level.");
          $('#divNoneToDisplay').fadeIn(0);
        }
        else {
          $('#divNoneToDisplay').text("No membership level is associated with your profile yet. To obtain this membership perk, please contact us!");
          $('#divNoneToDisplay').fadeIn(0);
        }

        Wallet.Page.clearLoadingMessage();
        return false;
      }

      Common.Http.makeGET("/wallet/member", {
        merchantID: Wallet.Configuration.merchantID,
        memberID: Wallet.memberID
      })
        .done(function (results) {
          $("#divMembershipLevel").fadeIn(0);
          Wallet.Page.clearLoadingMessage();
        })
        .fail(function (err) {
          $("#divMembershipLevel").fadeIn(0);
          Wallet.Page.clearLoadingMessage();
        });
    }
  },

  MerchantCredit: {
    load: function (data) {
      Wallet.Page.reset();

      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (phoneVerificationToken && !data) {
        Common.Http.makePOST("/wallet/paymentObject/token", {
          phoneVerificationToken: phoneVerificationToken,
          merchantID: Wallet.Configuration.merchantID,
          pageType: Common.Prefixes.MerchantCredit,
          referrer: document.referrer
        })
          .done(function (results) {
            data = (results && results.ID ? results : data);
            Wallet.MerchantCredit.display(data);
          })
          .fail(function (err) {
            Wallet.MerchantCredit.display();
            Wallet.Page.clearLoadingMessage();
          });
      } else {
        Wallet.MerchantCredit.display(data);
      }
    },
    display: function (data) {
      if (!data) {
        $("#divMerchantCredit").fadeIn(0);

        if (!Common.Cookie.get("CustomerMobileNumber")) {
          Wallet.Authenticate.show();
          $('#divNoneToDisplay').text("Please verify your phone number to access your store credit.");
          $('#divNoneToDisplay').fadeIn(0);
        }
        else {
          $('#divNoneToDisplay').text("No store credit is associated with your profile yet.");
          $('#divNoneToDisplay').fadeIn(0);
          Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.MerchantCredit), 'page_location': '(none for user)', 'send_page_view': true });
        }

        Wallet.Page.clearLoadingMessage();
        return false;
      }

      if (Wallet.isScaffoldingVisible) $("#divMerchantCredit").fadeIn(0);


      if (data.PaymentDesign) {
        Object.assign(Wallet.PaymentObject.Design, data.PaymentDesign);
        Wallet.PaymentObject.Design.name = data.PaymentDesign.displayName;
        Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon, true);
        Wallet.PaymentObject.displayDesign();
      }
      else {
        Wallet.PaymentObject.Design = {
          id: "default",
          borderColor: "#808080",
          borderStyleType: "Dashed",
          borderSize: "4px",
          fontColor: "#333333",
          fontType: "Tahoma",
          backgroundImageURL: "/img/background-min.jpg",
          icon: "<i class='fa-regular fa-credit-card'></i>"
        };
        Wallet.PaymentObject.displayDesign();
      }

      Wallet.customerCell = data.MerchantCredit.mobileNumber
      Wallet.memberID = data.MerchantCredit.memberID

      Wallet.PaymentObject.showMemberDetails();


      Wallet.Page.clearLoadingMessage();
      Wallet.PaymentObject.display();
      Wallet.PaymentObject.showValue(parseFloat((data.MerchantCredit.creditAmount / 100).toFixed(2)));


      if (!data.MerchantCredit.isActive) {
        return Wallet.PaymentObject.showUnusable('VOID');
      }

      var barcodeValue = data.ID;
      if (data.IsInfogenesisIntegrated) {
        barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(barcodeValue);
      }
      Wallet.Barcodes.show(barcodeValue);

      Integrations.Apple.Wallet.addLink({
        afterElement: "#paymentObject"
      });

      Wallet.Page.pushState(data);
      Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.MerchantCredit), 'page_location': data.ID, 'send_page_view': true });
    }
  },

  GiftCards: {
    load: function () {
      Wallet.Page.reset();

      setTimeout(function () {
        $("#divGiftCards").fadeIn(0);
        Wallet.Page.clearLoadingMessage();
      }, 500)
    }
  },

  GiftCertificates: {
    load: function () {
      Wallet.Page.reset();

      setTimeout(function () {
        $("#divGiftCertificates").fadeIn(0);
        Wallet.Page.clearLoadingMessage();
      }, 500)
    },
    getPublicKey: function () {

      Common.Http.makePOST("/stripe/configuration/fetchPublicKey", {})
        .done(function (result) {
          if (!result) {
            Wallet.Modal.alert("Error", "Unable to retrieve merchant's account.")
          }
          else {
            Wallet.GiftCertificates.publicKey = result;
          }
        })
        .fail(function (err) {
          Wallet.Modal.alert("Error", "Unable to retrieve merchant's account.")
        });

    }
  },


  Tickets: {
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.Tickets.display(data);
      }

      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (phoneVerificationToken) {
        Common.Http.makePOST("/wallet/tickets/fetchCustomerTicketsWithToken", {
          merchantID: Wallet.Configuration.merchantID,
          phoneVerificationToken: phoneVerificationToken,
        })
          .done(function (results) {
            Wallet.Tickets.displayAll(results);
          })
          .fail(function (err) {
            Wallet.Tickets.display();
            Wallet.Page.clearLoadingMessage();
          });
      }

      if (!data && !phoneVerificationToken) {
        Wallet.Tickets.display();
        Wallet.Page.clearLoadingMessage();
      }
    },
    display: function (data) {
      if (!data) {
        $("#divTickets").fadeIn(0);

        if (!Common.Cookie.get("CustomerMobileNumber")) {
          Wallet.Authenticate.show();
          $('#divNoneToDisplay').text("Please verify your phone number to access your tickets.");
          $('#divNoneToDisplay').fadeIn(0);
        }
        else {
          $('#divNoneToDisplay').text("No tickets are associated with your profile yet.");
          $('#divNoneToDisplay').fadeIn(0);
          Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.Tickets), 'page_location': '(none for user)', 'send_page_view': true });
        }

        Wallet.Page.clearLoadingMessage();
        return false;
      }

      if (Wallet.isScaffoldingVisible) $("#divTickets").fadeIn(0);

      if (data.PaymentDesign) {
        Object.assign(Wallet.PaymentObject.Design, data.PaymentDesign);
        Wallet.PaymentObject.Design.name = data.PaymentDesign.displayName;
        Wallet.PaymentObject.Design.icon = Common.Icons.generate(data.PaymentDesign.icon, true);
      }
      else {
        Wallet.PaymentObject.Design = {
          id: "default",
          borderColor: "#808080",
          borderStyleType: "Dashed",
          borderSize: "4px",
          fontColor: "#333333",
          fontType: "Tahoma",
          backgroundImageURL: "/img/background-min.jpg",
          icon: "<i class='fa-regular fa-ticket'></i>"
        };
      }
      //set PaymentObject properties
      Wallet.PaymentObject.displayDesign();

      Wallet.cellPhone = data.Ticket.recipientPhoneNumber
      Wallet.memberID = data.Ticket.recipientMemberID

      Wallet.PaymentObject.showMemberDetails((data.Ticket.quantity ? data.Ticket.quantity + " Tickets" : "1 Ticket"));

      Wallet.PaymentObject.display();


      var expirationDateTimeMs = (data.Ticket.Performance.ticketExpirationDateTime ? (new Date(data.Ticket.Performance.ticketExpirationDateTime)).getTime() : (new Date(data.Ticket.Performance.startDateTime).getTime() + (1000 * 60 * 60 * 4)));
      var expirationDateTime = (new Date(expirationDateTimeMs)).toISOString();
      var startAndExpiration = 'Begins: ' + Common.Date.formatTimeToAMPM(data.Ticket.Performance.startDateTime) + ' ' + Common.Date.format(data.Ticket.Performance.startDateTime) + ' <br/>Ends: ' + Common.Date.formatTimeToAMPM(expirationDateTime) + ' ' + Common.Date.format(expirationDateTime)

      Wallet.PaymentObject.showValidPeriod(undefined, undefined, startAndExpiration);
      Wallet.PaymentObject.showValue(data.Ticket.Performance.title);

      Wallet.Page.clearLoadingMessage();


      /*
      $("#divDebuggingInfo").html("<br/><br/><b>Additional information:</b><br/>" +
        "Active: " + (data.Ticket.isActive ? "Yes" : "No") + "<br/>" +
        "Expired: " + ((new Date()).getTime() > new Date(data.Ticket.Performance.startDateTime).getTime() ? "Yes" : "No") + "<br/>" +
        "Comp: " + (data.Ticket.isComp ? "Yes" : "No") + "<br/>" +
        "Claimed: " + (data.Ticket.isClaimed ? "Yes" : "No") + "<br/>" +
        "Date Claimed: " + (data.Ticket.dateTimeClaimed ? Common.Date.formatTime(data.Ticket.dateTimeClaimed) : "N/A") + "<br/>" +
        "Redeemed: " + (data.Ticket.isRedeemed ? "Yes" : "No") + "<br/>" +
        "Date Redeemed: " + (data.Ticket.dateTimeRedeemed ? Common.Date.formatTime(data.Ticket.dateTimeRedeemed) : "N/A") + "<br/>" +
        "Visitor Authenticated: " + (data.Ticket.dateTimeRedeemed ? Common.Date.formatTime(data.Ticket.dateTimeRedeemed) : "N/A") + "<br/>" +
        "").fadeIn(0)
        */


      if (!data.Ticket.isActive) {
        return Wallet.PaymentObject.showUnusable('VOID');
      }

      //has it been redeemed?
      if (data.Ticket.isRedeemed) {
        if (data.Ticket.dateTimeRedeemed) {
          return Wallet.PaymentObject.showUnusable('Redeemed On<br/>' + Common.Date.format(data.Ticket.dateTimeRedeemed));
        }
        else {
          return Wallet.PaymentObject.showUnusable('Redeemed');
        }
      }

      //has it expired?
      if ((new Date()).getTime() > expirationDateTimeMs) {
        return Wallet.PaymentObject.showUnusable('Expired On<br/>' + Common.Date.format(expirationDateTime));
      }

      /*
      if (data.Ticket.Performance.maxCompTickets) {
        return Wallet.PaymentObject.showUnusable('VOID');
      }
      */


      //good to go, display this baby
      $('#paymentUnusable').fadeOut(0);
      $('#paymentObject').css('opacity', 1);

      if (data.Ticket.isComp && !data.Ticket.isClaimed) {
        var maxCompTickets = data.Ticket.Performance.maxCompTickets || 0;
        if (data.Ticket.Performance.claimedSeats >= maxCompTickets) {
          $("#divSupplementalInfo").html("This complimentary ticket was not accepted in time.<br/>Event details are available <a href='#' onclick='Wallet.loadWallet(\"PF" + data.Ticket.merchantID + "\");'>here <i class='fa-regular fa-link'></i></a>.")
          $("#divSupplementalInfo").fadeIn(0)
          return Wallet.PaymentObject.showUnusable('MAX TICKETS<br/>HAVE BEEN CLAIMED');
        }
        if (!Wallet.Authenticate.isAuthenticated()) {
          $("#btnClaimTicket").html("<i class='fa-regular fa-lock'></i> Enter Phone Number to Reserve Ticket(s)")
        }
        else {
          $("#btnClaimTicket").html("<i class='fa-regular fa-ticket'></i> Reserve Ticket(s)")
        }

        $("#divSupplementalInfo").html("These complimentary tickets must be reserved before they can be redeemed for real tickets.")
        $("#divSupplementalInfo").fadeIn(0)
        $("#btnClaimTicket").fadeIn()

        $("#btnClaimTicket").on("click", function () {
          Wallet.Tickets.claimTicket(data.Ticket.id);
        })
      }
      else {
        if (data.Ticket.isComp && data.Ticket.isClaimed) {
          $("#divSupplementalInfo").html(data.Ticket.Performance.redemptionInstructions ? "<br/><span style='font-size:18px;'><b>Instructions to redeem and <br/>pick up physical tickets:<br/></b> " + data.Ticket.Performance.redemptionInstructions + "</span>" : "").fadeIn(0);
        }

        var barcodeValue = data.Ticket.id;
        if (data.IsInfogenesisIntegrated) {
          barcodeValue = Wallet.PaymentObject.getExtendedCodeValue(barcodeValue);
        }
        Wallet.Barcodes.show(barcodeValue);
      }


      /*
      Integrations.Apple.Wallet.addLink({
        afterElement: "#paymentObject"
      });
      */

      Wallet.Page.pushState(data);
      Integrations.Google.Analytics.trackEvent('event', 'page_view', { 'page_title': Common.Prefixes.convertToName(Common.Prefixes.Tickets), 'page_location': data.Ticket.id, 'send_page_view': true });
    },
    displayAll: function (tickets) {
      $('#divVerticalList').empty();
      if (!Wallet.isScaffoldingVisible) return;

      if (tickets && tickets.length > 0) {

        var sortedTickets = tickets.sort(function (x, y) {
          return ((new Date(x.Performance.startDateTime)).getTime() > (new Date(x.Performance.startDateTime)).getTime() ? 1 : -1);
        })

        $('#collapsibleEvents').empty();
        $('#collapsibleEvents').append("<br/><br/>");

        var upcomingEvents = [];
        var pastEvents = [];
        $.each(sortedTickets, function () {

          //employees[0].isOnline = true;
          //employees[0].hasLiveChat = true;

          //const profilePictureURL = this.profilePictureURL ? this.profilePictureURL : '/img/user-no-image.jpg';
          var claimed = (this.isClaimed ? '<div class="mobile-wallet-item-phone-number"><i class="fa-regular fa-ticket"></i> CLAIMED</div>' : (this.isComp ? '<div class="mobile-wallet-item-phone-number"><i class="fa-regular fa-triangle-exclamation"></i> UNCLAIMED (INVITED)</div>' : ''));
          var redeemed = (this.isRedeemed ? '<div class="mobile-wallet-item-phone-number"><i class="fa-regular fa-check"></i> REDEEMED</div>' : '');
          var comp = (this.isComp ? '<div class="mobile-wallet-item-role"><i class="fa-regular fa-gift"></i> COMPLIMENTARY</div>' : '');
          var startDateTime = (this.Performance.startDateTime ? '<div class="mobile-wallet-item-role"><i class="fa-regular fa-calendar"></i> ' + Common.Date.formatTime(this.Performance.startDateTime) + '</div>' : '');

          var expirationDateTime = (this.Performance.ticketExpirationDateTime ? new Date(this.Performance.ticketExpirationDateTime).getTime() : new Date(this.Performance.startDateTime).getTime() + (1000 * 60 * 60 * 4));
          var isExpired = (new Date().getTime() > expirationDateTime ? true : false);
          var expired = (isExpired ? '<div class="mobile-wallet-item-phone-number"><i class="fa-regular fa-ban"></i> EXPIRED</div>' : '');

          //var department = this.department.length > 0 ? '<div class="mobile-wallet-item-dept"><i class="fa-light fa-users-between-lines"></i> ' + this.department + '</div>' : ""

          var mediaURL = this.Performance.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.Performance.mediaURL + '" alt="" /></div>' : '';

          var item = '<a onclick="Wallet.loadWallet(\'' + this.id + '\');">' +
            '<div class="mobile-wallet-item">' +
            '<div class="mobile-wallet-item-details">' +
            mediaURL +
            '<h4 class="mobile-wallet-item-title">' + this.Performance.title + '</h4>' +
            '<div class="mobile-wallet-item-body" style="padding-top:0px;">' +
            '<div class="description">' +
            comp +
            claimed +
            redeemed +
            expired +
            startDateTime +
            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</a>';


          if (expired) {
            pastEvents.push(item);
          }
          else {
            upcomingEvents.push(item);
          }
        });

        if (upcomingEvents.length > 0) {
          $('#collapsibleEvents').append("<h4 class='h4-header'><i class='fa-light fa-calendar-days'></i> Upcoming Events</h4>");
          upcomingEvents.map(function (event) {
            $('#collapsibleEvents').append(event);
          })

          /*
          if (pastEvents.length > 0) {
            $('#collapsibleEvents').append("<h4 class='h4-header'><i class='fa-light fa-clock'></i> Past Events</h4>");
            pastEvents.map(function (event) {
              $('#collapsibleEvents').append(event);
            })
          }
          */

          $('div[data-role=collapsible]').collapsible();
          $('#collapsibleEvents').fadeIn();
        }
        else {
          $('#divNoneToDisplay').html("<br/><br/>No tickets for future events are associated with your profile.");
          $('#divNoneToDisplay').fadeIn(0);
        }

      }
      else {
        $('#divNoneToDisplay').html("No tickets are associated with your profile yet.");
        $('#divNoneToDisplay').fadeIn(0);
      }
      if (Wallet.isScaffoldingVisible) $("#divTickets").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
    },
    claimTicket: function (ticketID) {
      if (!Wallet.Authenticate.isAuthenticated()) {
        Wallet.Login.load(ticketID)
      }
      else {
        Wallet.Page.showLoadingMessage("Claiming...");

        Common.Http.makePUT("/wallet/ticket/claim/" + ticketID, { claimedByPhoneNumber: Common.Cookie.get("CustomerMobileNumber") })
          .done(function (result) {
            Integrations.Google.Analytics.trackEvent('event', 'ticket_claimed');
            return Wallet.loadWallet(ticketID);
          })
          .fail(function (result) {
            Wallet.Page.clearLoadingMessage();
            Wallet.Performances.display();
          })
      }
    },
  },

  Performances: {
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.Performances.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Performances + Wallet.Configuration.merchantID)
      }
    },
    display: function (data) {
      $("#divPerformances").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Performances.showEvents(data.Performances);
      Wallet.Page.pushState(data);
    },
    generateIcs: function (id) {
      Common.Http.makeGET("/wallet/liveevent/ics/" + id, {})
        .done(function (result) {
          Integrations.Google.Analytics.trackEvent('event', 'event_saved_to_calendar');
          Common.File.downloadIcs("Event.ics", result);
        })
        .fail(function (result) {
          Wallet.Modal.alert("Error", "Unable to save event.")
        })
    },
    showNone: function () {
      $('#divNoneToDisplay').text('Please check back soon for exciting events!');
      $('#divNoneToDisplay').fadeIn(0);
      return;
    },
    showEvents: function (events) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(events) || events.length === 0) {
        Wallet.Performances.showNone();
      }

      $.each(events, function () {
        if ((new Date(this.startDateTime)).getTime() < (new Date()).getTime()) return true;

        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var headerImageClass = mediaURL == '' || mediaURL == undefined ? 'mobile-wallet-item-no-header-image' : '';
        var price = "";
        if (this.isSoldOut) {
          price = '<span class="badge-sold-out badge badge-danger">' + Common.Icons.generate('fa-tag') + ' SOLD OUT</span>';
        }
        else if (isNaN(this.price)) {
          price = '<span class="badge-price badge badge-success">' + Common.Icons.generate('fa-money-bill-wave') + ' ' + this.price + '</span>';
        }
        else if (parseFloat(this.price) === 0) {
          price = '<span class="badge-price badge badge-success">' + Common.Icons.generate('fa-money-bill-wave') + ' FREE</span>';
        }
        else if (parseFloat(this.price) > 0) {
          price = '<span class="badge-price badge badge-info">' + Common.Icons.generate('fa-money-bill-wave') + ' ' + Common.Integer.formatToCurrency(this.price * 100, Common.Currencies.get(Wallet.Merchant.currency).symbol) + '</span>';
        }

        var saveToCalendar = '<button onclick="Wallet.Performances.generateIcs(\'' + this.id + '\'); " class="btn btn-secondary-right">' + Common.Icons.generate("calendar-arrow-down") + ' Save to Calendar</button>'
        var moreInfo = '<button onclick="Wallet.launchExternalLink(\'' + this.url + '\');" class="btn btn-secondary-right" style="float:left;">More Info <i class="fa-regular fa-square-arrow-up-right"></i></button>';
        var startDate = '<span class="badge badge-secondary">' + Common.Date.formatTime(this.startDateTime) + '</span>';
        var description = (this.body + '').replaceAll("\n", "<br/>");

        var item = '<div class="mobile-wallet-item ' + headerImageClass + '">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="description">' +
          '<div class="mobile-wallet-item-badges">' +
          startDate +
          price +
          '</div>' +
          '<p>' + Common.String.truncateToWords(Common.String.linkfy(description, 30), 50) + '</p>' +
          (this.url ? moreInfo : "") + 
          saveToCalendar +
          '</div>' +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      if ($("#collapsibleEvents").children().length === 0) {
        Wallet.Performances.showNone();
      } else {
        $('div[data-role=collapsible]').collapsible();
        $('#collapsibleEvents').fadeIn();
        $('#divPerformances').fadeIn(0);
      }

    }
  },

  NewsArticles: {

    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.NewsArticles.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.NewsArticles + Wallet.Configuration.merchantID);
      }

    },
    display: function (data) {
      $("#divNews").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.NewsArticles.showNews(data.NewsArticles);
      Wallet.Page.pushState(data);
    },
    showNews: function (events) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(events) || events.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for exciting news!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(events, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var description = (this.body + '').replaceAll("\n", "<br/>");
        var body = Common.String.linkfy(description, 30) + (this.url ? '<br/><br/><button style="float:right;" class="btn btn-sm btn-default btn-send-chat" onclick="Wallet.launchExternalLink(\'' + this.url + '\');">Continue Reading <i class="fa fa-arrow-up-right-from-square"></i></button><br/>' : '');
        var publishedDate = this.publishedDate ? new Date(this.publishedDate) : new Date(this.createdAt);
        var newsDate = publishedDate ? publishedDate : this.createdAt;
        var today = new Date();


        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<span class="mobile-wallet-date">' + Common.Date.format(newsDate) + '</span>' +
          '<div class="mobile-wallet-item-body">' + body + '</div>' +
          '</div>' +
          '</div>';

        if (publishedDate && (publishedDate.getTime() <= today.getTime())) $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divNews').fadeIn(0);
    },
  },


  PromoCodes: {
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.PromoCodes.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.PromoCode + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divPromoCodes").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.PromoCodes.showPromos(data.PromoCodes);
      Wallet.Page.pushState(data);
    },
    showPromos: function (promos) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(promos) || promos.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for exciting promotions!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      var validRecordCount = 0;
      $.each(promos, function () {
        if ((new Date(this.startDate)).getTime() > (new Date()).getTime()) return true;
        if ((new Date(this.expirationDate)).getTime() < (new Date()).getTime()) return true;
        validRecordCount++;

        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayValue = this.displayValue ? '<span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayValue, 30) + '</span>' : '';
        var promoCode = this.promoCode ? '<span class="badge-code badge badge-info">' + Common.Icons.generate("tag") + ' Code: ' + Common.String.truncateToCharacters(this.promoCode, 25) + '</span>' : '';
        var startDate = '<span class="badge badge-secondary">Starts: ' + Common.Date.formatTime(this.startDate) + '</span>';
        var expirationDate = '<span class="badge badge-warning">Expires: ' + Common.Date.formatTime(this.expirationDate) + '</span>';
        var qrCode = '<img style="width: 125px;height:125px;" src="' + Common.getAPIUrl() + '/wallet/item/qr/' + this.id + '" />';
        var description = (this.description + '').replaceAll("\n", "<br/>");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          '<div class="mobile-wallet-item-badges">' +
          displayValue +
          promoCode +
          startDate +
          expirationDate +
          '</div>' +
          '<div class="qr-code">' +
          qrCode +
          '</div>' +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      if (validRecordCount === 0) {
        $('#divNoneToDisplay').text('Please check back soon for exciting promotions!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divPromoCodes').fadeIn(0);
    }
  },




  Products: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.Products.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Product + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divProducts").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Products.showProducts(data.Products);
      Wallet.Page.pushState(data);
    },
    showProducts: function (events) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(events) || events.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our products!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(events, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> More Information</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divProducts').fadeIn(0);
    }
  },

  Services: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.Services.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Service + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divServices").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Services.showServices(data.Services);
      Wallet.Page.pushState(data);
    },
    showServices: function (events) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(events) || events.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our services!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(events, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> Check Availability</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divServices').fadeIn(0);
    }
  },


  RoomRates: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.RoomRates.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.RoomRates + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divRoomRates").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.RoomRates.showRoomRates(data.RoomRates);
      Wallet.Page.pushState(data);
    },
    showRoomRates: function (events) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(events) || events.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our rooms & rates!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(events, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> Check Availability</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divRoomRates').fadeIn(0);
    }
  },


  Amenities: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.Amenities.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Amenity + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divAmenities").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Amenities.showAmenities(data.Amenities);
      Wallet.Page.pushState(data);
    },
    showAmenities: function (amenities) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(amenities) || amenities.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our amenities!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(amenities, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> More Information</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divAmenities').fadeIn(0);
    }
  },



  Dining: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.Dining.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Dining + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divDining").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Dining.showDining(data.Dining);
      Wallet.Page.pushState(data);
    },
    showDining: function (dining) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(dining) || dining.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our dining options!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(dining, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> More Information</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divDining').fadeIn(0);
    }
  },



  Gaming: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.Gaming.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Gaming + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divGaming").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Gaming.showGaming(data.Gaming);
      Wallet.Page.pushState(data);
    },
    showGaming: function (gaming) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(gaming) || gaming.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our gaming options!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(gaming, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> More Information</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divGaming').fadeIn(0);
    }
  },



  Lounges: {
    load: function (data) {
      Wallet.Page.reset();
      if (data) {
        Wallet.Lounges.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Lounge + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divLounges").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.Lounges.showLounges(data.Lounges);
      Wallet.Page.pushState(data);
    },
    showLounges: function (lounges) {
      $('#collapsibleEvents').empty();
      if (!Array.isArray(lounges) || lounges.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for a look at our lounges!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(lounges, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img"><img src="' + this.mediaURL + '" alt="" /></div>' : '';
        var displayedPrice = this.displayedPrice ? '<div class="mobile-wallet-item-badges"><span class="badge-display-value badge badge-success">' + Common.Icons.generate("money-bill-wave") + ' ' + Common.String.truncateToCharacters(this.displayedPrice, 30) + '</span></div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> More Information</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          mediaURL +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          '<div class="mobile-wallet-item-body">' +
          '<div class="display-flex mobile-wallet-item-description">' +
          displayedPrice +
          '</div>' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divLounges').fadeIn(0);
    }
  },

  //Displays a single business card
  VirtualBusinessCard: {
    load: function (data) {
      Wallet.Page.reset();

      $("#divVirtualBusinessCard").fadeIn(0);

      var headerImageURL = data.WalletConfiguration.headerImageURL ? '<div class="mobile-wallet-item-img"><img src="' + data.WalletConfiguration.headerImageURL + '" alt="" /></div>' : '';
      var headerImageClass = data.WalletConfiguration.headerImageURL == '' || data.WalletConfiguration.headerImageURL == undefined ? 'mobile-wallet-item-no-header-image' : '';


      //removes white space
      var whitespace = new RegExp("[ ]+", "g");
      var lb = new RegExp("[(]+", "g");
      var rb = new RegExp("[)]", "g");
      var p = new RegExp("[.]", "g");
      var d = new RegExp("[-]", "g");
      var parsedPhone = data.VirtualBusinessCard.phoneNumber ? data.VirtualBusinessCard.phoneNumber.replace(whitespace, "").replace(lb, "").replace(rb, "").replace(p, "") : "";
      var parsedWhatsApp = data.VirtualBusinessCard.whatsApp ? data.VirtualBusinessCard.whatsApp.replace(whitespace, "").replace(lb, "").replace(rb, "").replace(p, "") : "";

      var avatar = data.VirtualBusinessCard.avatarURL ? "<div class='avatar-div-above-card'><img class='avatar-image-above-card' src='" + data.VirtualBusinessCard.avatarURL + "'/></div>" : "";
      var designation = data.VirtualBusinessCard.designation ? "<div style='text-align:center;'>" + data.VirtualBusinessCard.designation + "</div>" : "";
      var introduction = data.VirtualBusinessCard.introduction ? "<div style='text-align:center;'>" + data.VirtualBusinessCard.introduction + "</div><br/>" : "";

      var phoneNumber = parsedPhone ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"tel:" + parsedPhone + "\");'><div class='bg-danger business-card-button'><i class='fa-thin fa-phone-volume' style='color:#fff;'></i></div>Call</a></div>" : "";
      var whatsApp = parsedWhatsApp ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"https://wa.me/" + parsedWhatsApp + "\");'><div class='bg-social-whatsapp business-card-button'><i class='fa-brands fa-whatsapp' style='color:#fff;'></i></div>WhatsApp</a></div>" : "";
      var emailAddress = data.VirtualBusinessCard.emailAddress ? "<div class='business-card-button-wrapper'><a onclick='Wallet.launchExternalLink(\"mailto:" + data.VirtualBusinessCard.emailAddress + "\");'><div class='bg-info business-card-button'><i class='fa-thin fa-envelope' style='color:#fff;'></i></div>Email</a></div>" : "";


      var instagram = data.VirtualBusinessCard.instagram ? "<div class='business-card-button-wrapper-sm'><a onclick='Wallet.launchExternalLink(\"https://instagram.com/" + data.VirtualBusinessCard.instagram + "\");'><div class='bg-social-instagram business-card-button'><i class='fa-brands fa-instagram' style='color:#fff;'></i></div></a></div>" : "";
      var facebook = data.VirtualBusinessCard.facebook ? "<div class='business-card-button-wrapper-sm'><a onclick='Wallet.launchExternalLink(\"https://facebook.com/" + data.VirtualBusinessCard.facebook + "\");'><div class='bg-default business-card-button'><i class='fa-brands fa-facebook' style='color:#fff;'></i></div></a></div>" : "";
      var youTube = data.VirtualBusinessCard.youTube ? "<div class='business-card-button-wrapper-sm'><a onclick='Wallet.launchExternalLink(\"https://youtube.com/" + data.VirtualBusinessCard.youTube + "\");'><div class='bg-default business-card-button'><i class='fa-brands fa-youtube' style='color:#fff;'></i></div></a></div>" : "";
      var twitter = data.VirtualBusinessCard.twitter ? "<div class='business-card-button-wrapper-sm'><a onclick='Wallet.launchExternalLink(\"https://twitter.com/" + data.VirtualBusinessCard.twitter + "\");'><div class='bg-default business-card-button'><i class='fa-brands fa-twitter' style='color:#fff;'></i></div></a></div>" : "";
      var linkedin = data.VirtualBusinessCard.linkedIn ? "<div class='business-card-button-wrapper-sm'><a onclick='Wallet.launchExternalLink(\"https://linkedin.com/in/" + data.VirtualBusinessCard.linkedIn + "\");'><div class='bg-default business-card-button'><i class='fa-brands fa-linkedin' style='color:#fff;'></i></div></a></div>" : "";

      var connect = instagram || facebook || youTube || twitter || linkedin ? "Connect with me on<br/>" : ""

      var cardDetails = '<div class="mobile-wallet-item ' + headerImageClass + '">' +
        '<div class="mobile-wallet-item-details">' +
        headerImageURL +
        '<div class="mobile-wallet-item-body" >' +
        avatar +
        "<h4 style='text-align:center;padding-bottom:-10px;'>" + data.VirtualBusinessCard.firstName + " " + data.VirtualBusinessCard.lastName + "</h4>" +
        designation +
        introduction +
        "<div style='text-align:center;width:100%;padding:0px;margin:0px;'>" +
        phoneNumber +
        whatsApp +
        emailAddress +
        "</div>" +
        "<div style='clear:both;'></div>" +
        "<div style='text-align:center;margin:40px 0px 0px 0px;'><button class='btn btn-sm btn-secondary' onclick='Wallet.VirtualBusinessCard.generateVCard(\"" + data.VirtualBusinessCard.id + "\", \"" + data.VirtualBusinessCard.firstName + " " + data.VirtualBusinessCard.lastName + "\")'><i class='fa fa-user-plus'></i> Save Contact</button></div>" +

        "<div style='text-align:center;width:100%;padding:0px;margin:20px 0px 0px 0px;'>" +
        connect +
        instagram +
        facebook +
        youTube +
        twitter +
        linkedin +
        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';

      $("#divVirtualBusinessCardPage").html(cardDetails);

      Wallet.Page.clearLoadingMessage();
    },
    generateVCard: function (id, cardholderName) {
      Common.Http.makeGET("/wallet/virtualBusinessCard/vcard/" + id, {})
        .done(function (result) {
          Integrations.Google.Analytics.trackEvent('event', 'business_card_saved');
          Common.File.downloadVCard(cardholderName + ".vcf", result);
        })
        .fail(function (result) {
          Wallet.Modal.alert("Error", "Unable to save virtual business card VCard.")
        })
    }
  },


  LinkBook: {
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.LinkBook.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.LinkBook + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divLinkBook").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
      Wallet.LinkBook.showLinks(data.LinkBook, data.LinkBookSections);
      Wallet.Page.pushState(data);
    },
    showLinks: function (events, linkBookSections) {
      $('#divVerticalList').empty();
      if (!Array.isArray(events) || events.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for our most popular links!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      //this handles the SECTION LOGIC
      var sectionIDs = [];

      $('#divVerticalList').append('<div id="divQuickLinksSection-default" class="link-book-section"><div class="link-book-title"></div></div>');

      if (linkBookSections && linkBookSections.length && linkBookSections.length > 0) {
        $.each(linkBookSections, function () {
          sectionIDs.push(this.id)
          $('#divVerticalList').append('<div id="divQuickLinksSection-' + this.id + '" class="link-book-section"><div class="link-book-title">' + this.name + '</div></div>')
        });
      }

      $.each(events, function () {
        var divID = "divQuickLinksSection-";
        if (sectionIDs.includes(this.linkBookSectionID)) {
          divID += this.linkBookSectionID;
        } else {
          divID += "default";
        }

        $('#' + divID).append('<div class="link-book-item">' +
          '<a onclick="Wallet.launchExternalLink(\'' + this.url + '\');" class="link-book-item-link">' +
          '<div style="background-color:#fff;font-size:20px;" class="ellipsis-overflow">' +
          '<div class="link-book-item-icon">' + Common.Icons.generate(this.icon) + '</div> ' +
          Common.String.capitalizeFirstLetter(this.title) +
          '</div>' +
          '</a>' +
          '</div>');
      });

      // Check if any sections are empty
      sectionIDs.push("default");
      for (var i = 0; i < sectionIDs.length; i++) {
        if ($("#divQuickLinksSection-" + sectionIDs[i]).children().length <= 1) {
          // Hide this
          $("#divQuickLinksSection-" + sectionIDs[i]).css("display", "none");
        }
      }

      $('#divVerticalList').fadeIn();
      $('#divLinkBook').fadeIn(0);
    }
  },


  ImageGrid: {
    load: function (data) {
      Wallet.loadLibrary("simplelightbox", "/js/lib/simplelightbox/simple-lightbox.jquery.js");
      Wallet.loadStylesheet("simplelightboxStyle", "/js/lib/simplelightbox/simple-lightbox.min.css");
      Wallet.Page.reset();

      if (data) {
        Wallet.ImageGrid.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.ImageGrid + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divImageGrid").fadeIn(0);
      Wallet.ImageGrid.showImages(data.ImageGrid);
      Wallet.Page.pushState(data);
    },
    showImages: function (images) {
      console.log(images);
      $('#divVerticalList').empty();
      if (!Array.isArray(images) || images.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for an updated image gallery!');
        $('#divNoneToDisplay').fadeIn(0);
        Wallet.Page.clearLoadingMessage();
        return;
      }

      $('#divVerticalList').append("<div id='divFlexGrid' class='gallery row' style='margin:2px;'></div>");

      var pinnedImages = []
      var nonPinnedImages = $.grep(images, function (el, i) {
        if (el.isPinned) {
          pinnedImages.push(el);
          return false;
        }
        return true;
      });

      $.each(pinnedImages, function () {
        Wallet.ImageGrid.appendImage(this)
      });
      $.each(nonPinnedImages, function () {
        Wallet.ImageGrid.appendImage(this)
      });


      setTimeout(function () {
        $('.gallery a')
          .simpleLightbox({
            docClose: false,
            captionsData: 'data-title',
          })
          .on('closed.simplelightbox', function () {
          });
      }, 500);


      setTimeout(function () {
        Wallet.Page.clearLoadingMessage();
        $('#wallet').show();
        $('#divVerticalList').fadeIn();
        $('#divImageGrid').fadeIn(0);
      }, 2000);
    },
    appendImage: function (image) {

      var url = image.url ? "<br/><a onclick=Wallet.launchExternalLink(\'" + image.url + "\');>" + image.url + "</a>" : "";
      var item = '<a href="' + image.mediaURL + '" class="big" rel="rel1">' +
        '<img src="' + image.mediaURL + '" alt="' + image.title + '" title="' + image.title + '" data-title="' + image.title + url + '">' +
        '</a>';
      $('#divFlexGrid').append(item);
    }
  },




  Videos: {
    load: function (data) {
      Wallet.Page.reset();

      if (data) {
        Wallet.Videos.display(data)
      }
      else {
        return Wallet.loadWallet(Common.Prefixes.Video + Wallet.Configuration.merchantID) 
      }
    },
    display: function (data) {
      $("#divVideos").fadeIn(0);
      Wallet.Videos.showVideos(data.Videos);
      Wallet.Page.pushState(data);
    },
    showVideos: function (videos) {
      $('#divVerticalList').empty();
      Wallet.Page.clearLoadingMessage();
      if (!Array.isArray(videos) || videos.length === 0) {
        $('#divNoneToDisplay').text('Please check back soon for some exciting videos!');
        $('#divNoneToDisplay').fadeIn(0);
        return;
      }

      $.each(videos, function () {
        var mediaURL = this.mediaURL ? '<div class="mobile-wallet-item-img">' + this.mediaURL + '</div>' : '';
        var description = (this.description + '').replaceAll("\n", "<br/>");
        var additionalInfo = (this.additionalInfoURL ? '<button class="btn btn-sm btn-default btn-secondary-right" onclick="Wallet.launchExternalLink(\'' + this.additionalInfoURL + '\');"><i class="fa-light fa-external-link"></i> More Information</button>' : "");

        var item = '<div class="mobile-wallet-item">' +
          '<div class="mobile-wallet-item-details">' +
          '<h4 class="mobile-wallet-item-title">' + this.title + '</h4>' +
          mediaURL +
          '<div class="mobile-wallet-item-body">' +
          '<div class="description">' +
          '<p>' + Common.String.linkfy(description, 30) + '</p>' +
          '</div>' +
          additionalInfo +
          '</div>' +
          '</div>' +
          '</div>';
        $('#collapsibleEvents').append(item);
      });

      $('div[data-role=collapsible]').collapsible();
      $('#collapsibleEvents').fadeIn();
      $('#divVideos').fadeIn(0);
      Wallet.Page.clearLoadingMessage();
    }
  },



  Chat: {
    kitty: "",
    channel: "",
    chatSession: "",
    userSession: "",
    load: function () {
      Wallet.loadStylesheet("chatbubblesStle", "/css/chat-message-bubbles.css")
      Wallet.Page.reset();

      Wallet.Page.clearLoadingMessage();
      if (!Wallet.Authenticate.isAuthenticated()) {
        Wallet.Authenticate.show();
      }
      else {
        if (Wallet.Chat.userSession) return Wallet.Chat.createDirect();
        else {
          Wallet.Page.showLoadingMessage("Initializing chat...");
          Wallet.loadLibrary("scriptChatKitty", "/js/lib/chatkitty-1.49.2.js", Wallet.Chat.startSession);
        }
      }
    },

    startSession: function () {
      Wallet.Chat.kitty = ChatKitty.default.getInstance(Common.chatKittyAppID);

      Wallet.Chat.kitty.startSession({
        username: Common.Cookie.get("CustomerMobileNumber")
      })
        .then(function (session) {
          Wallet.Chat.userSession = session;
          Wallet.Chat.createDirect();
        })
        .catch(function (err) {
          Wallet.Modal.alert("Error", "Unable to start a chat session.")
          console.log(JSON.stringify(err))
        })
    },
    createDirect: function () {
      if (Wallet.Chat.channel) Wallet.Chat.useDirectChannel();
      else {
        Wallet.Chat.kitty.createChannel({
          type: 'DIRECT',
          members: [
            { username: Wallet.Configuration.merchantID },
            { username: Common.Cookie.get("CustomerMobileNumber") }
          ],
        })
          .then(function (result) {
            if (result.succeeded) {
              Wallet.Chat.channel = result.channel; // Handle channel
              Wallet.Chat.useDirectChannel();
            }

            if (result.failed) {
              Wallet.Modal.alert("Error", "Unable to connect to an agent.")
              console.log(result.error);
            }
          })
          .catch(function () {
            Wallet.Modal.alert("Error", "Unable to connect to an agent.")
          })
      }
    },
    useDirectChannel: function () {
      Wallet.Chat.getMessages();
      Wallet.Chat.createChatSession();
    },
    getMessages: function () {
      Wallet.Chat.kitty.getMessages({
        channel: Wallet.Chat.channel,
      })
        .then(function (messages) {
          Wallet.Chat.messagesReceived = messages;
          if (Wallet.Chat.messagesReceived.succeeded) {
            Wallet.Page.clearLoadingMessage();

            var messages = Wallet.Chat.messagesReceived.paginator.items
            if (messages && messages.length > 0) {
              messages.reverse();
            }

            $.each(messages, function () {
              Wallet.Chat.createBubble(this);
            });
            $("#divChatMessages").css("max-height", window.innerHeight - 230 + "px")
            $("#divChatMessages").css("height", window.innerHeight - 230 + "px")
            $("#txtChatInput").css("width", parseInt($("#divChatMessages").width() - $("#btnChatSendMessage").width() - 20))
          }

          if (Wallet.Chat.messagesReceived.failed) {
            Wallet.Modal.alert("Error", "Unable to retrieve chat messages.")
            console.error(Wallet.Chat.messagesReceived.error);
          }
        })
        .catch(function (err) {
          Wallet.Modal.alert("Error", "Unable to retrieve chat messages.")
          console.error(err)
        })
    },
    createChatSession: function () {
      var result;
      if (Wallet.Chat.chatSession) {
        result = Wallet.Chat.chatSession;
      }
      else {
        result = Wallet.Chat.kitty.startChatSession({
          channel: Wallet.Chat.channel,
          onReceivedMessage: function (message) {
            Wallet.Chat.createBubble(message);
          },
          onReceivedKeystrokes: function (keystrokes) {
            console.log(keystrokes)
          },
          onTypingStarted: function (user) {
            Wallet.Chat.createTypingStatus();
          },
          onTypingStopped: function (user) {
            Wallet.Chat.endTypingStatus();
          },
          onParticipantEnteredChat: function (user) {
            Wallet.Chat.createStatusUpdate("Customer service has entered the chat.");
          },
          onParticipantLeftChat: function (user) {
            Wallet.Chat.createStatusUpdate("Customer service has left the chat.");
          },
          onParticipantPresenceChanged: function (user) {
            //console.log(user)
          },
          onMessageRead: function (message, receipt) {
            console.log(message, receipt)
          },
          onMessageUpdated: function (message) {
            console.log(message)
          },
          onChannelUpdated: function (channel) {
            console.log(channel)
          },
        });
      }

      if (result.succeeded) {
        $("#divChatSupport").fadeIn(0);
        Wallet.Chat.chatSession = result; // Handle session        
      }

      if (result.failed) {
        Wallet.Modal.alert("Error", "Unable to create chat session.")
        console.error(result.error);
      }
    },
    sendMessage: function () {
      if ($("#txtChatInput").val() === "") return;
      Wallet.Chat.kitty.sendMessage({
        channel: Wallet.Chat.channel,
        body: $("#txtChatInput").val(),
      }).then(function (message) {
        Wallet.Chat.messageSent = message;
        $("#txtChatInput").val("")

        if (Wallet.Chat.messageSent.succeeded) {
          console.log(Wallet.Chat.messageSent.message)
        }

        if (Wallet.Chat.messageSent.failed) {
          Wallet.Modal.alert("Error", "Unable to send message.")
          console.error(Wallet.Chat.messageSent.error);
        }

      })
    },
    createBubble: function (message) {
      var bubble = "";
      if (message.user.name === Common.Cookie.get("CustomerMobileNumber")) {
        bubble = "<div class='mine messages'><div class='message last'>" + message.body + "</div></div>"
      }
      else {
        bubble = "<div class='yours messages'><div class='message last'>" + message.body + "</div></div>"
      }
      $("#divChatMessages").append(bubble);
      $('#divChatMessages').scrollTop($('#divChatMessages').prop('scrollHeight'));
    },
    createStatusUpdate: function (msg) {
      $("#divChatMessages").append("<span>" + msg + "</span>");
      $('#divChatMessages').scrollTop($('#divChatMessages').prop('scrollHeight'));
    },
    createTypingStatus: function (msg) {
      $("#divChatMessages").append("<span id='spanTyping'>Customer service is typing...</span>");
      $('#divChatMessages').scrollTop($('#divChatMessages').prop('scrollHeight'));
    },
    endTypingStatus: function (msg) {
      $("#spanTyping").remove();
      $('#divChatMessages').scrollTop($('#divChatMessages').prop('scrollHeight'));
    },

  },
  Room: {
    kitty: "",
    channel: "",
    chatSession: "",
    userSession: "",
    load: function () {
      Wallet.Page.reset();

      Wallet.Page.clearLoadingMessage();
      if (!Wallet.Authenticate.isAuthenticated()) {
        Wallet.Authenticate.show();
      }
      else {
        Wallet.Page.showLoadingMessage("Initializing chat...");

        Wallet.loadLibrary("chatKitty", "/js/lib/chatkitty-1.49.2.js", Wallet.Room.startSession);
      }
    },

    startSession: function () {
      Wallet.Room.kitty = ChatKitty.default.getInstance(Common.chatKittyAppID);

      Wallet.Room.kitty.startSession({
        username: Common.Cookie.get("CustomerMobileNumber")
      })
        .then(function (session) {
          Wallet.Room.userSession = session;
          Wallet.Room.joinPublic();
        })
        .catch(function () {
          Wallet.Modal.alert("Error", "Unable to start chat session.")
          console.error("Error starting session.")
        })
    },
    joinPublic: function () {
      if (!Wallet.Configuration.publicChatRoomChannelID) return false;

      Wallet.Room.kitty.getChannel(Wallet.Configuration.publicChatRoomChannelID)
        .then(function (result) {
          var channel = {}

          if (result.succeeded) {
            channel = result.channel; // Handle channel
          }

          if (result.failed) {
            Wallet.Modal.alert("Error", "Unable to locate group channel.")
            console.error(result.failed)
          }


          Wallet.Room.kitty.joinChannel({
            channel: channel
          })
            .then(function (result) {
              if (result.succeeded) {
                Wallet.Room.channel = result.channel; // Handle channel
                Wallet.Room.getMessages();
                Wallet.Room.createChatSession();
              }

              if (result.failed) {
                Wallet.Modal.alert("Error", "Unable to join group chat.")
                console.error(result.failed)
              }
            })
            .catch(function (error) {
              Wallet.Modal.alert("Error", "Unable to join group chat.")
              console.error(error)
            })
        })
        .catch(function (error) {
          Wallet.Modal.alert("Error", "Unable to locate group chat.")
          console.error(error)
        })

    },
    getMessages: function () {
      Wallet.Room.kitty.getMessages({
        channel: Wallet.Room.channel,
      })
        .then(function (messages) {
          Wallet.Room.messagesReceived = messages;
          if (Wallet.Room.messagesReceived.succeeded) {
            Wallet.Page.clearLoadingMessage();

            $.each(Wallet.Room.messagesReceived.paginator.items, function () {
              Wallet.Room.createBubble(this);
            });
            $("#divRoomMessages").css("height", "350px")
            $("#txtRoomInput").css("width", parseInt($("#divRoomMessages").width() - $("#btnRoomSendMessage").width() - 20))
          }

          if (Wallet.Room.messagesReceived.failed) {
            Wallet.Modal.alert("Error", "Unable to get messages.")
            console.error(Wallet.Room.messagesReceived.error);
          }
        })
        .catch(function (err) {
          Wallet.Modal.alert("Error", "Unable to get messages.")
          console.error("Failed to get messages.")
        })
    },
    createChatSession: function () {
      var result = Wallet.Room.kitty.startChatSession({
        channel: Wallet.Room.channel,
        onReceivedMessage: function (message) {
          Wallet.Room.createBubble(message);
        },
        onReceivedKeystrokes: function (keystrokes) {
          console.log(keystrokes)
        },
        onTypingStarted: function (user) {
          Wallet.Room.createTypingStatus();
        },
        onTypingStopped: function (user) {
          Wallet.Room.endTypingStatus();
        },
        onParticipantEnteredChat: function (user) {

        },
        onParticipantLeftChat: function (user) {
        },
        onParticipantPresenceChanged: function (user) {
          //console.log(user)
        },
        onMessageRead: function (message, receipt) {
          console.log(message, receipt)
        },
        onMessageUpdated: function (message) {
          console.log(message)
        },
        onChannelUpdated: function (channel) {
          console.log(channel)
        },
      });

      if (result.succeeded) {
        $("#divChatRoom").fadeIn(0);
        Wallet.Room.chatSession = result.session; // Handle session
      }

      if (result.failed) {
        Wallet.Modal.alert("Error", "Unable to create chat session.")
        console.error(result.error);
      }
    },
    sendMessage: function () {
      if ($("#txtRoomInput").val() === "") return;
      Wallet.Room.kitty.sendMessage({
        channel: Wallet.Room.channel,
        body: $("#txtRoomInput").val(),
      }).then(function (message) {
        Wallet.Room.messageSent = message;
        $("#txtRoomInput").val("")

        if (Wallet.Room.messageSent.succeeded) {
          console.log(Wallet.Room.messageSent.message)
        }

        if (Wallet.Room.messageSent.failed) {
          Wallet.Modal.alert("Error", "Unable to send message.")
          console.error(Wallet.Room.messageSent.error);
        }

      })
    },
    createBubble: function (message) {
      var bubble = "";
      if (message.user.name === Common.Cookie.get("CustomerMobileNumber")) {
        bubble = "<div class='mine messages'><div class='message last'>" + message.body + "</div></div>"
      }
      else {
        bubble = "<div class='yours messages'><div class='message last'>" + message.body + "</div></div>"
      }
      $("#divRoomMessages").append(bubble);
      $('#divRoomMessages').scrollTop($('#divRoomMessages').prop('scrollHeight'));
    },
    createStatusUpdate: function (msg) {
      $("#divRoomMessages").append("<span>" + msg + "</span>");
      $('#divRoomMessages').scrollTop($('#divRoomMessages').prop('scrollHeight'));
    },
    createTypingStatus: function (msg) {
      $("#divRoomMessages").append("<span id='spanTyping'>Customer service is typing...</span>");
      $('#divRoomMessages').scrollTop($('#divRoomMessages').prop('scrollHeight'));
    },
    endTypingStatus: function (msg) {
      $("#spanTyping").remove();
      $('#divRoomMessages').scrollTop($('#divRoomMessages').prop('scrollHeight'));
    },

  },

  Contact: {
    load: function () {
      return Wallet.loadWallet(Wallet.Configuration.id);
    }
  },

  Transactions: {
    load: function () {
      Wallet.Page.reset();

      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (phoneVerificationToken) {
        Common.Http.makePOST("/wallet/page/token", {
          phoneVerificationToken: phoneVerificationToken,
          merchantID: Wallet.Configuration.merchantID,
          pageType: Common.Prefixes.Transactions,
          referrer: document.referrer
        })
          .done(function (results) {
            Wallet.Transactions.display(results);
          })
          .fail(function (err) {
            Wallet.Transactions.display();
          });
      } else {
        Wallet.Transactions.display();
      }
    },
    display: function (data) {
      Wallet.Page.clearLoadingMessage();

      $("#divTransactions").fadeIn(0);
      if (!data || !data.Transactions || !data.Transactions.length === 0) {
        var msg = "We don't have any transactions associated with your profile.<br /><br />Please check back after redeeming some rewards with us!";

        if (!Common.Cookie.get("PhoneVerificationToken")) {
          msg = "Please verify your phone number to view past transactions.<br/>"
          Wallet.Authenticate.show();
        }
        return $("#divTransactionsDetails").html(msg);
      }



      var rowsExist = false;
      var headers = ["#", "Type", "Discount", "Check", "Out/In", "Date"];
      var rows = [];

      for (var i = 0; i < data.Transactions.length; i++) {
        var result = data.Transactions[i];
        var row = [
          (i + 1) + ".",
          Common.Prefixes.convertToIcon(result.paymentObjectPrefix),
          result.discountReceived,
          Common.Integer.formatToCurrency(result.checkAmount, Common.Currencies.get(Wallet.Merchant.currency).symbol),
          (result.transactionType === "redemption" ? "<i class='fa fa-up' style='color:green'></i>" : "<i class='fa fa-down' style='color:red'></i>"),
          Common.Date.formatTime(result.createdAt),
        ]
        rowsExist = true;
        rows.push(row);
      }

      var html = (rowsExist ? Common.Html.formatTabulatedDataWithHeadersAndRows(headers, rows, true) : 'You have no redemptions or refunds with us yet.');

      $('#divTransactionsDetails').html(html);
    }
  },

  Profile: {
    load: function () {
      Wallet.Page.reset();

      setTimeout(function () {
        $("#divProfile").fadeIn(0);
        Wallet.Page.clearLoadingMessage();
      }, 500)
    }
  },

  Settings: {
    load: function () {
      Wallet.Page.reset();

      setTimeout(function () {
        $("#divSettings").fadeIn(0);
        Wallet.Page.clearLoadingMessage();
      }, 500)
    }
  },

  Barcodes: {
    //Barcode related functions
    show: function (barcodeValue) {
      Wallet.Barcodes.showQR(barcodeValue);
    },

    showQR: function (barcodeValue, correctLevel) {
      $("#divBarcodeTarget").empty();

      var qrcode = new QRCode(document.getElementById("divBarcodeTarget"), {
        width: 60,
        height: 60,
        correctLevel: (correctLevel ? correctLevel : QRCode.CorrectLevel.L)
      });

      qrcode.makeCode(barcodeValue);

      if (Wallet.PaymentObject && Wallet.PaymentObject.Design && Wallet.PaymentObject.Design.borderSize) {
        var borderInt = parseInt(Wallet.PaymentObject.Design.borderSize.replace("px", ""));
        $("#divBarcodeTarget").css("margin-right", ((borderInt * 2) + 6) + "px")
        $("#divBarcodeTarget").css("margin-bottom", borderInt + "px")
      }

      $('#divBarcodeTarget').show();
    },
  },


  //This is the merchant landing page for the Wallet, when switching between companies.
  SmsOptIn: {
    load: function (data) {

      Wallet.Page.reset();
      if (
        (data === Common.Prefixes.SmsOptInTemplate + Wallet.Configuration.merchantID) ||
        (data.ID === Common.Prefixes.SmsOptInTemplate + Wallet.Configuration.merchantID)
      ) {
        Wallet.optInSourceID = Common.Prefixes.SmsOptInTemplate + Wallet.Configuration.merchantID;
        Wallet.optInListID = "";

        var privacyLink = '» <a href="' + Common.smsPrivacyPolicyURL + '" target="_blank">Privacy Policy</a><Br/>'
        var termsLink = '» <a href="' + Common.smsTermsOfServiceURL + '" target="_blank">Terms of Service</a>'
        $("#spanSmsOptInCompanyName").text(Wallet.Merchant.companyName);
        $("#spanSmsMerchantNumber").text("").fadeOut(0);
        $("#spanSmsPolicyLinks").html(privacyLink + termsLink);

        if (Common.Cookie.get("PhoneVerificationToken") && Common.Cookie.get("CustomerMobileNumber")) {
          var num = Common.Cookie.get("CustomerMobileNumber")
          $("#txtOptInNumber").val(num.replace("+1", ""))
        }
      }
      else {
        Wallet.optInSourceID = data.SmsOptInSource.id;
        Wallet.optInListID = data.SmsOptInSource.listID;
        Wallet.merchantNumber = data.MerchantNumber.mobileNumber;

        //var privacyLink = '» <a href="' + data.MerchantNumber.privacyPolicyURL + '" target="_blank">Privacy Policy</a><Br/>'
        //var termsLink = '» <a href="' + data.MerchantNumber.termsOfServiceURL + '" target="_blank">Terms of Service</a>'
        var privacyLink = '» <a href="' + Common.smsPrivacyPolicyURL + '" target="_blank">Privacy Policy</a><Br/>'
        var termsLink = '» <a href="' + Common.smsTermsOfServiceURL + '" target="_blank">Terms of Service</a>'
        $("#spanSmsOptInCompanyName").text(data.Merchant.companyName);
        $("#spanSmsMerchantNumber").html("We send messages from " + Common.PhoneNumber.format(data.MerchantNumber.mobileNumber) + "<br/><br/>").fadeIn(0);
        $("#spanSmsPolicyLinks").html(privacyLink + termsLink);

        if (Common.Cookie.get("PhoneVerificationToken") && Common.Cookie.get("CustomerMobileNumber")) {
          var num = Common.Cookie.get("CustomerMobileNumber")
          $("#txtOptInNumber").val(num.replace("+1", ""))
        }
        Wallet.Page.pushState(data);
      }

      $("#divSmsOptIn").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
    },
    subscribe: function () {
      if (Wallet.optInSourceID === Common.Prefixes.SmsOptInTemplate + Wallet.Configuration.merchantID) {
        Common.Http.makePOST("/wallet/subscribeSms", {
          merchantID: Wallet.Configuration.merchantID,
          mobileNumber: $("#txtOptInNumber").val(),
        })
          .done(function (result) {
            Wallet.SmsOptIn.showSuccessfulSubscription(Wallet.optInSourceID);
            Integrations.Google.Analytics.trackEvent('event', 'subscribed_sms');
          })
          .fail(function (err) {
            if (err && err.status && err.status == 500 && err.responseJSON && err.responseJSON.code === "SMSB-310") {
              //User is already a subscriber, show them success, not an error.
              Wallet.SmsOptIn.showSuccessfulSubscription(Wallet.optInSourceID);
            }
            else if (err && err.status && err.status == 500 && err.responseJSON && err.responseJSON.code && err.responseJSON.message) {
              Wallet.Modal.alert("Error", err.responseJSON.message)
              console.error("An error occured when trying to subscribe. Please try again later.");
            }
            else {
              Wallet.Modal.alert("Error", "Unable to subscribe. Please try again later.")
              console.error("An error occured when trying to subscribe. Please try again later.");
            }
            Wallet.Page.clearLoadingMessage();
          });
      }
      else if (Wallet.optInSourceID && Wallet.optInListID && Wallet.optInListID.length > 1 && Wallet.optInSourceID.length > 1) {
        Common.Http.makePOST("/tcpa/opt", {
          merchantID: Wallet.Configuration.merchantID,
          phoneNumber: $("#txtOptInNumber").val(),
          sourceID: Wallet.optInSourceID,
          listID: Wallet.optInListID
        })
          .done(function (result) {
            Wallet.SmsOptIn.showSuccessfulSubscription(Wallet.optInSourceID);
            Integrations.Google.Analytics.trackEvent('event', 'subscribed_sms');
          })
          .fail(function (err) {
            if (err && err.status && err.status == 409) {
              //User is already a subscriber, show them success, not an error.
              Wallet.SmsOptIn.showSuccessfulSubscription(Wallet.optInSourceID);
            }
            if (err && err.status && err.status == 403) {
              Wallet.SmsOptIn.showResubscribeSteps("You previously unsubscribed by texting STOP. <Br/>To resubscribe, please text START to " + Wallet.merchantNumber + ".");
            } else if (err && err.status && err.status == 413) {
              Wallet.SmsOptIn.showResubscribeSteps("Merchant has exhausted their SMS limit. Try again later.");
            }
            else {
              Wallet.Modal.alert("Error", "Unable to subscribe. Please try again later.")
              console.error("An error occured when trying to subscribe. Please try again later.");
            }
            Wallet.Page.clearLoadingMessage();
          });
      }
    },
    showSuccessfulSubscription: function (optInSourceID) {
      //show the menu, removing the override setting
      Wallet.OverrrideSettings.forceMenuHidden = false;
      localStorage.setItem("SmsSubscribe-" + Wallet.Configuration.merchantID, "true");
      Wallet.Menu.load();

      $("#btnLeftNavMenu").addClass("attention-getter")
      setTimeout(function () {
        $("#btnLeftNavMenu").removeClass("attention-getter")
      }, 5000)

      Wallet.Page.clearLoadingMessage();
      Wallet.optInSourceID = '';
      Wallet.optInListID = '';
      $("#divOptInNumber").fadeOut(0);
      $("#divOptInNumberSuccess").fadeIn(0);
      if (optInSourceID) Wallet.loadWallet(optInSourceID);
    },
    showResubscribeSteps: function (msg) {
      Wallet.Page.clearLoadingMessage();
      $("#divOptInNumberError").html(msg);
      $("#divOptInNumberError").fadeIn(0);
    }
  },
  //This is the merchant landing page for the Wallet, when switching between companies.
  SmsOptOut: {
    load: function (data) {
      Wallet.Page.reset();

      $("#divSmsOptOut").fadeIn(0);

      Wallet.Page.clearLoadingMessage();
    }
  },
  EmailSubscribe: {
    load: function () {
      Wallet.Page.reset();
      $("#divEmailSubscribeForm").fadeIn(0);
      $("#divEmailSubscribe").fadeIn(0);
      Wallet.Page.clearLoadingMessage();
    },
    subscribe: function () {
      Wallet.Page.showLoadingMessage();
      Common.Http.makePOST("/wallet/subscribeEmail", {
        merchantID: Wallet.Configuration.merchantID,
        firstName: $("#txtEmailSubscribeFirstName").val(),
        lastName: $("#txtEmailSubscribeLastName").val(),
        emailAddress: $("#txtEmailSubscribeEmailAddress").val(),
      })
        .done(function (result) {
          Wallet.Page.clearLoadingMessage();
          $("#divEmailSubscribeForm").fadeOut(0);
          $("#divEmailSubscribeSubmitted").fadeIn(0);
          localStorage.setItem("EmailSubscribe-" + Wallet.Configuration.merchantID, "true");
          Wallet.Menu.load();
          Integrations.Google.Analytics.trackEvent('event', 'subscribed_email');
          setTimeout(function () {
            Wallet.loadWallet(Wallet.Configuration.id)
          }, 2000)
        })
        .fail(function (err) {
          if (err && err.responseJSON && err.responseJSON.message) {
            $("#divEmailSubscribeError").html(err.responseJSON.message)
            $("#divEmailSubscribeError").fadeIn(0);

            if (err.responseJSON.code === "EMSB-310") {
              localStorage.setItem("EmailSubscribe-" + Wallet.Configuration.merchantID, "true");
              setTimeout(function () {
                Wallet.loadWallet(Wallet.Configuration.id)
              }, 2000)
            }
          }
          Wallet.Page.clearLoadingMessage();
        });
    },
    /*
    openStorage: function () {
      var merchantsSubscribed = [];
      var key = "EmailSubscriptions";
      if (localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)).length > 0) {
        merchantsSubscribed = JSON.parse(localStorage.getItem(key))
        if (merchantsSubscribed.length > 0) {
          return merchantsSubscribed.pop();
        }
      }
      return false;
    },
    updateStorage: function (data) {
      var adCredits = [];
      var key = "AdCredits-" + Wallet.Configuration.merchantID;
      if (localStorage.getItem(key) && JSON.parse(localStorage.getItem(key)).length > 0) {
        adCredits = JSON.parse(localStorage.getItem(key))
        if (adCredits.indexOf(data.ID) == -1) {
          adCredits.push(data.ID)
          localStorage.setItem(key, JSON.stringify(adCredits));
        }
      }
      else {
        adCredits.push(data.ID)
        localStorage.setItem(key, JSON.stringify(adCredits));
      }
    },
    */
  },

  Authenticate: {
    isAuthenticated: function () {
      return (Common.Cookie.get("PhoneVerificationToken") && Common.Cookie.get("CustomerMobileNumber"))
    },
    show: function () {
      var phoneVerificationToken = Common.Cookie.get("PhoneVerificationToken");
      if (!phoneVerificationToken)
        Wallet.Authenticate.displayPromptPhone();
    },
    hide: function () {
      Wallet.Authenticate.reset();
      $("#divAuthenticatePhoneNumber").hide();
    },
    displayPromptPhone: function () {
      Wallet.Authenticate.reset();
      $("#divAuthenticatePhoneNumber").show();
    },
    displayPromptOTP: function () {
      $("#divPhoneNumber").hide();
      $("#divPhoneNumberOTP").show();

      $("#btnAuthenticatePhoneNumber").hide();
      $("#btnAuthenticateOTP").show();
    },
    reset: function () {
      $("#divSupplementalInfo").fadeOut(0)
      $("#divSupplementalInfo").html("")
      $("#btnClaimTicket").fadeOut(0)
      $("#btnClaimTicket").off("click");

      $("#divDebuggingInfo").fadeOut(0)
      $("#divDebuggingInfo").html("")

      $("#txtPhoneNumber").val("")
      $("#txtPhoneNumberOTP").val("")

      $("#divPhoneNumber").show()
      $("#divPhoneNumberOTP").hide()

      $("#btnAuthenticatePhoneNumber").show();
      $("#btnAuthenticateOTP").hide();

      $("#divAmount").css("font-size", "54px");
    },
    phoneNumber: function () {

      var num = Common.PhoneNumber.castToUS($("#txtPhoneNumber").val());
      if (!num)
        return false;

      Wallet.Page.showLoadingMessage();
      Common.Http.makePOST("/twilio/authy/requestCode", {
        merchantID: Wallet.Configuration.merchantID,
        phoneNumber: num
      })
        .done(function (result) {
          Wallet.Page.clearLoadingMessage();
          console.log(JSON.stringify(result))
          if (result.success === true) {
            Wallet.Authenticate.displayPromptOTP()
          }
        })
        .fail(function (err) {
          Wallet.Modal.alert("Error", "Request for OTP failed! Please try again.")
          Wallet.Page.clearLoadingMessage();
        });
    },

    oneTimePasscode: function () {
      var num = Common.PhoneNumber.castToUS($("#txtPhoneNumber").val());

      var otp = $("#txtPhoneNumberOTP").val();
      if (!otp)
        return false;

      Wallet.Page.showLoadingMessage();
      Common.Http.makePOST("/twilio/authy/verifyCode", {
        merchantID: Wallet.Configuration.merchantID,
        phoneNumber: num,
        otp: otp
      })
        .done(function (result) {
          Wallet.Page.clearLoadingMessage();
          console.log(JSON.stringify(result))
          if (result.success === true) {
            Wallet.customerCell = num;
            Common.Cookie.save("PhoneVerificationToken", result.token);
            Common.Cookie.save("CustomerMobileNumber", num);

            Integrations.Google.Analytics.trackEvent('event', 'authenticated_mobile_number');

            if (Wallet.Login.postLoginRedirectPage && Wallet.Login.postLoginRedirectPage.substring(0, 2) === Common.Prefixes.Ticket) {
              //User is trying to claim a ticket, was prompted to authenticate, succeeded auth, now we auto-claim it for them.
              Wallet.Tickets.claimTicket(Wallet.Login.postLoginRedirectPage);
            }
            else if (Wallet.Login.postLoginRedirectPage) {
              return Wallet.loadWallet(Wallet.Login.postLoginRedirectPage);
            }
            else {
              return Wallet.loadWallet(Wallet.Configuration.id);
            }
          }
          else {
            Wallet.Modal.alert("Error", "Verification failed!")
          }
        })
        .fail(function (err) {
          Wallet.Modal.alert("Error", "Verification failed!")
          Wallet.Authenticate.reset();

          Wallet.Page.clearLoadingMessage();
        });
    },

  },

  Modal: {
    alert: function (title, body) {
      $("#modalAlertTitle").text(title);
      $("#modalAlertBody").text(body);
      $("#modalAlert").modal();
    }
  },
  removeVerification: function () {
    Common.Cookie.delete("PhoneVerificationToken");
    Common.Cookie.delete("CustomerMobileNumber");
    $("#iconPhoneVerified").fadeOut(0);
    Wallet.Merchants.close();
    Integrations.Google.Analytics.trackEvent('event', 'authenticated_logout');
    return Wallet.loadWallet(Wallet.Configuration.id);
  },
  fetchMerchants: function () {
    Common.Http.makeGET("/system/industries/trimmed", {})
      .done(function (industries) {
        Common.Http.makeGET("/wallet/merchants/mobileNumber/" + Common.Cookie.get("CustomerMobileNumber"), {})
          .done(function (result) {
            $("#divActiveMemberships").empty();
            if (!result || !result.length || result.length === 0) {
              $("#divActiveMemberships").html("<Br/>No active memberships.");
            }

            var currentlyViewedMerchantIndustry = 0;
            result.map(function (merchant) {
              if (Wallet.Configuration.merchantID === merchant.id)
                currentlyViewedMerchantIndustry = merchant.industry;
            })

            var sortedResults = result.sort(function (x, y) {
              return (parseInt(x.industry) > parseInt(y.industry)) ? 1 : -1;
            })



            var oldIndustryID = "";
            var newIndustryID = "";
            $.each(sortedResults, function () {
              newIndustryID = this.industry;
              var industry = industries.filter(function (industry) {
                return (industry.id == newIndustryID)
              })
              if (!industry[0] || !industry[0].id) return;

              var industryGroupDivID = "divIndustryGroup" + industry[0].id;

              if (newIndustryID != oldIndustryID) {
                var isVisible = (newIndustryID === currentlyViewedMerchantIndustry);
                var industryGroup = "<div><div class='menuIndustryGroup' onclick='Wallet.toggle(\"" + industryGroupDivID + "\")'><div style='float:left;width:30px;text-align:center;'><i class='fa-regular fa-" + industry[0].icon + "'></i></div>" + " " + industry[0].title + (isVisible ? "<i style='float:right;margin: 4px 10px 0px 0px;' class='mm-arrow fa-regular fa-chevron-circle-down'></i>" : "<i style='float:right;margin: 4px 10px 0px 0px;' class='mm-arrow fa-regular fa-chevron-circle-right'></i>") + "</div><div style='" + (isVisible ? "display:block;" : "display:none;") + "' id='" + industryGroupDivID + "'></div></div>";
                $("#divActiveMemberships").append(industryGroup);
              }

              if (this.isFeatured) {
                $("#" + industryGroupDivID).append("<div class='merchant-button-featured'>FEATURED</div>");
              }

              if (this.WalletConfiguration && this.WalletConfiguration.companyLogoURL) {
                var headerBackgroundColor = this.WalletConfiguration.headerBackgroundColor ? this.WalletConfiguration.headerBackgroundColor : '#fff';
                $("#" + industryGroupDivID).append('<div onclick="Wallet.loadWallet(\'' + this.WalletConfiguration.id + '\')" class="merchantButton" style="background: ' + headerBackgroundColor + '"><img src="' + this.WalletConfiguration.companyLogoURL + '" height="50" width="209" /></div>');
              }
              else {
                $("#" + industryGroupDivID).append('<div onclick="Wallet.loadWallet(\'' + this.WalletConfiguration.id + '\')" class="merchantButton" style="height:50px !important;">' + this.companyName + '</div>');
              }
              oldIndustryID = newIndustryID;
            })
          })
          .fail(function (err) {
            console.log(JSON.stringify(err))
          });
      })
      .fail(function (err) {
        console.log(JSON.stringify(err))
      });
  },
  toggle: function (divID) {
    $("#" + divID).fadeToggle(100);
    var arrow = $("#" + divID).prev().find(".mm-arrow");
    if (arrow.hasClass("fa-chevron-circle-right")) {
      arrow.removeClass("fa-chevron-circle-right").addClass("fa-chevron-circle-down");
    }
    else if (arrow.hasClass("fa-chevron-circle-down")) {
      arrow.removeClass("fa-chevron-circle-down").addClass("fa-chevron-circle-right");
    }
  },
  loadLibrary: function (id, src, callback) {
    var js;
    var firstJS = document.getElementsByTagName("script")[0];
    if (document.getElementById(id)) { return; }
    js = document.createElement("script");
    js.id = id;
    js.onload = function () {
      if (typeof callback === "function") callback()
    };
    js.onerror = function (err) {
      Wallet.Modal.alert("Error", "Unable to load library.")
      console.error(JSON.stringify(err))
    };
    js.src = src;
    firstJS.parentNode.insertBefore(js, firstJS);
  },
  loadStylesheet: function (id, src, callback) {
    var css;
    var firstCSS = document.getElementsByTagName("link")[0];
    if (document.getElementById(id)) { return; }
    css = document.createElement("link");
    css.id = id;
    css.onload = function () {
      if (typeof callback === "function") callback()
    };
    css.onerror = function (err) {
      Wallet.Modal.alert("Error", "Unable to load css file.")
      console.error(JSON.stringify(err))
    };
    css.rel = "stylesheet";
    css.href = src;
    firstCSS.parentNode.insertBefore(css, firstCSS);
  },

  GDPRCookie: {

    // Create cookie
    set: function (cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    // Delete cookie
    delete: function (cname) {
      var d = new Date();
      d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=;" + expires + ";path=/";
    },

    // Read cookie
    get: function (cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    // Set cookie consent
    accept: function () {
      Wallet.GDPRCookie.delete('user_cookie_consent');
      Wallet.GDPRCookie.set('user_cookie_consent', 1, 30);
      document.getElementById("cookieNotice").style.display = "none";
    },

    check: function () {
      var cookie_consent = Wallet.GDPRCookie.get("user_cookie_consent");
      if (cookie_consent != "") {
        document.getElementById("cookieNotice").style.display = "none";
      } else {
        document.getElementById("cookieNotice").style.display = "block";
      }
    }

  },

  AgeGate: {

    // Create cookie
    set: function (cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    },

    // Delete cookie
    delete: function (cname) {
      var d = new Date();
      d.setTime(d.getTime() + (24 * 60 * 60 * 1000));
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=;" + expires + ";path=/";
    },

    // Read cookie
    get: function (cname) {
      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(';');
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    // Set cookie consent
    accept: function () {
      Wallet.AgeGate.delete('user_minimum_age');
      Wallet.AgeGate.set('user_minimum_age', Wallet.Configuration.ageGateMinimum, 30);
      document.getElementById("modalAgeNotice").style.display = "none";
      $("#modalAgeNotice").modal('toggle');
      Integrations.Google.Analytics.trackEvent('event', 'age_gate_accepted');
    },
    decline: function () {
      Integrations.Google.Analytics.trackEvent('event', 'age_gate_declined');
      var redirectURL = Wallet.Configuration.ageGateDeclineURL || 'https:\/\/wallet.inc';

      if (Common.Window.inIframe()) {
        window.parent.location.href = redirectURL;
      }
      else {
        window.location.href = redirectURL;
      }
    },
    check: function (ageMinimum) {
      if (window && window.frameElement && window.frameElement.getAttribute("data-scaffolding") === "hide") {
        return true;
      }
      var age = Wallet.AgeGate.get("user_minimum_age");
      if (age != "" && age >= ageMinimum) {
        document.getElementById("modalAgeNotice").style.display = "none";
      } else {
        $("#spanAgeGateMinimum").text(ageMinimum);
        $("#modalAgeNotice").modal();
        document.getElementById("modalAgeNotice").style.display = "block";
      }
    },

    _forceShow: function (ageMinimum) {
      $("#spanAgeGateMinimum").text(ageMinimum);
      $("#modalAgeNotice").modal();
      document.getElementById("modalAgeNotice").style.display = "block";
    },
    _forceHide: function () {
      $("#modalAgeNotice").modal('hide');
    },

  },

  WebSocket: {
    socket: null,
    initialize: function () {
      if (Wallet.WebSocket.socket) return; //we have an active socket, abort.

      var socketURL = Common.getAPIUrl().split("://")[1];
      var wsProtocol = "";
      if (location.protocol == "http:") {
        wsProtocol = "ws:";
      } else if (location.protocol == "https:") {
        wsProtocol = "wss:";
      }
      try {
        var socket = new WebSocket(wsProtocol + "//" + socketURL);
        socket.onopen = function (e) {
          console.log("[open] Connection established");
          console.log("Sending to server");
          // socket.send("My name is John");
        };
        socket.onmessage = function (event) {
          console.log("[message] Data received from server: " + event.data);
          var message = JSON.parse(event.data);
          console.log(message);
          //Admin.UI.Notifications.Success.show(message.title, message.content, 5000);
        };
        socket.onclose = function (event) {
          Wallet.WebSocket.socket = null;
          if (event.wasClean) {
            console.log("[close] Connection closed cleanly, code=" + event.code + " reason=" + event.reason);
          } else {
            // e.g. server process killed or network down
            // event.code is usually 1006 in this case
            console.log("Connection closed uncleanly.");
          }
        };
        socket.onerror = function (error) {
          console.log("Connection error");
          try {
            socket.close();
            Wallet.WebSocket.socket = null;
          } catch (e) { };

          setTimeout(function () {
            // Retry connection after 2 seconds
            try {
              console.log("Attempting to reconnect.");
              Wallet.WebSocket.setupWebSocket();
            } catch (e) { };
          }, 2000);
        };
        Wallet.WebSocket.socket = socket;
      } catch (e) {
        console.log("Error trying to initialize.");
        setTimeout(function () {
          try {
            console.log("Attempting to reconnect.");
            Wallet.WebSocket.setupWebSocket();
          } catch (e) { };
        }, 2000);
      }
    },
  }
};



var Integrations = {
  Apple: {
    Wallet: {
      addLink: function (config) {
        if (!config || !config.afterElement) return false;

        var navigatorAgent = navigator.userAgent;
        if (!navigatorAgent) return false;

        var device = new UAParser(navigatorAgent).getResult();
        device = (typeof device.os.name === "string" ? device.os.name.toLowerCase() : undefined);
        if (device === "ios" && Wallet.Configuration.isAppleEnabled) {
          $("#svgAddToAppleWallet").insertAfter(config.afterElement);
          $("#svgAddToAppleWallet").css("display", "block");
          $("#svgAddToAppleWallet").click(function () {
            Integrations.Google.Analytics.trackEvent('event', 'apple_wallet_pass_added');
            window.open(Common.getAPIUrl() + '/partners/apple/wallet/v1/passes/generate/' + Wallet.itemID);
          });

          //Integrations.Apple.Store.addLink({});
        }
      },
      hideLink: function (config) {
        $("#svgAddToAppleWallet").css("display", "none");
        $("#svgAddToAppleWallet").click(function () {
        });
      }
    },
    Store: {
      addLink: function (config) {
        if (!Wallet.Configuration.appleAppStoreURL && Wallet.Merchant.isWhiteLabeled) {
          return;
        }

        var navigatorAgent = navigator.userAgent;
        if (!navigatorAgent) return false;

        var device = new UAParser(navigatorAgent).getResult();
        device = (typeof device.os.name === "string" ? device.os.name.toLowerCase() : undefined);

        if (Wallet.Configuration.appleAppStoreURL) {
          $("#divDownloadOnAppStore").on("click", function () {
            Wallet.launchExternalLink(Wallet.Configuration.appleAppStoreURL)
          });
        }
        else {
          $("#divDownloadOnAppStore").on("click", function () {
            Wallet.launchExternalLink(Wallet.poweredByWalletIncURL)
          });
        }

        if (device === "ios") {
          $("#walletFooter").fadeIn();
          $("#divDownloadOnAppStore").fadeIn();
        }
      },
      hideLink: function (config) {
        $("#divDownloadOnAppStore").fadeOut();
      }
    }
  },
  Google: {
    Analytics: {
      currentID: "",
      load: function () {
        try {
          //clear Google Analytics Property if new ID
          if (Wallet.Configuration.googleAnalyticsID != Integrations.Google.Analytics.currentID) {
            $("#scriptGoogleAnalytics4").remove();
            console.log("GA4 script unloaded from DOM");
            if (typeof gtag == "function") {
              gtag = undefined;
              console.log("gtag set to undefined")
            }
          }

          //connect Google Analytics Property
          if (Wallet.Configuration.googleAnalyticsID && Wallet.Configuration.googleAnalyticsID.length > 0) {
            if ($("#scriptGoogleAnalytics4").length > 0 && typeof gtag == "function") {
              Integrations.Google.Analytics.trackEvent('event', 'page_view', {
                'page_title': Common.Prefixes.convertToName(Wallet.itemID.substring(0, 2)),
                'page_location': Wallet.itemID,
                'send_page_view': true,
              });
              return;
            }

            // Insert google analytics scripts here
            var script = document.createElement("script");
            script.src = "https://www.googletagmanager.com/gtag/js?id=" + Wallet.Configuration.googleAnalyticsID;

            script.async = true;

            script.onload = function () {
              var inline = document.createElement("script");
              inline.type = "text/javascript";
              inline.id = "scriptGoogleAnalytics4";
              inline.textContent = "window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '" + Wallet.Configuration.googleAnalyticsID + "', {'page_title': '" + Common.Prefixes.convertToName(Wallet.itemID.substring(0, 2)) + "' ,'page_location': '" + Wallet.itemID + "' ,'send_page_view': true }); "
              //  gtag('event', 'select_content', { content_type: '" + object.FunctionalType + "', item_id: '" + object.ID + "' });
              //  gtag('event', 'Advertisement Credit', {'event_category': 'Wallet Page View','event_label': '" + object.ID + "','value': 2});
              document.getElementsByTagName('head')[0].appendChild(inline);
            }

            document.getElementsByTagName('head')[0].appendChild(script);
            Integrations.Google.Analytics.currentID = Wallet.Configuration.googleAnalyticsID;
          }
        }
        catch (err) {
          console.error("Google Analytics loading error.")
        }
      },
      trackEvent: function (event, data, options) {
        if (typeof gtag != "function") return; //google analytics isn't loaded into the DOM for this merchant. Exit.

        try {
          if (Common.Cookie.get("CustomerMobileNumber")) {
            if (typeof options == "object") {
              options["verified_mobile_number"] = Common.Cookie.get("CustomerMobileNumber")
            }
            else {
              options = { "verified_mobile_number": Common.Cookie.get("CustomerMobileNumber") }
            }
          }

          gtag(event, data, options);
          console.log("Event tracked: " + data);
        }
        catch (err) {
          console.error("Google analytics event failed to track.");
        }
      }
    },
    Wallet: {
      addLink: function (config) {

      }
    },
    Store: {
      addLink: function (config) {        
        if (!Wallet.Configuration.googlePlayStoreURL && Wallet.Merchant.isWhiteLabeled) {
          return;
        }

        var navigatorAgent = navigator.userAgent;
        if (!navigatorAgent) return false;

        var device = new UAParser(navigatorAgent).getResult();
        device = (typeof device.os.name === "string" ? device.os.name.toLowerCase() : undefined);

        if (Wallet.Configuration.googlePlayStoreURL) {
          $("#divDownloadOnGooglePlay").on("click", function () {
            Wallet.launchExternalLink(Wallet.Configuration.googlePlayStoreURL)
          });
        }
        else {
          $("#divDownloadOnGooglePlay").on("click", function () {
            Wallet.launchExternalLink(Wallet.poweredByWalletIncURL)
          });
        }

        if (device === "android") {
          $("#walletFooter").fadeIn();
          $("#divDownloadOnGooglePlay").fadeIn();
        }
      },
      hideLink: function (config) {
        $("#divDownloadOnGooglePlay").fadeOut();
      }
    }
  },
  Facebook: {
    Pixel: {
      load: function () {

        // Reset Facebook Pixel
        if (Wallet.Configuration.facebookPixelID != Wallet.currentlyFacebookPixelID) {
          $("#scriptFacebookPixelID").remove();
          console.log("FB pixel unloaded from DOM");
          if (typeof fbq != "function") {
            fbq = undefined;
            console.log("fbq set to undefined")
          }
        }
        // Connect Facebook Pixel
        if (Wallet.Configuration.facebookPixelID && Wallet.Configuration.facebookPixelID.length > 0) {
          if ($("#scriptFacebookPixelID").length > 0) return;

          // Insert Facebook Pixel script here
          var facebookPixelScript = document.createElement("script");
          facebookPixelScript.async = true;
          facebookPixelScript.type = "text/javascript";
          facebookPixelScript.id = "scriptFacebookPixelID";
          facebookPixelScript.textContent = "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';n.queue=[];t=b.createElement(e);t.async=!0;t.src=v;s=b.getElementsByTagName(e)[0];s.parentNode.insertBefore(t,s)}(window, document,'script','https://connect.facebook.net/en_US/fbevents.js');fbq('init', '" + Wallet.Configuration.facebookPixelID + "');fbq('track', 'PageView');"

          facebookPixelScript.onload = function () {
            if ($("#noscriptFacebookPixelID").length > 0) return;
            var facebookPixelNoScript = document.createElement("noscript");
            facebookPixelNoScript.async = true;
            facebookPixelNoScript.id = "noscriptFacebookPixelID";
            facebookPixelNoScript.textContent = '<img height="1" width="1" style="display:none" src="https://www.facebook.com/tr?id=' + Wallet.Configuration.facebookPixelID + '&ev=PageView&noscript=1"/>';
            document.getElementsByTagName('head')[0].appendChild(facebookPixelNoScript);
          }

          document.getElementsByTagName('head')[0].appendChild(facebookPixelScript);
        }
      }
    }
  },

  Instagram: {
    embed: function () {

    }
  },
}



